/**
 * This page is cloned from the invoices page so it might
 * have similar naming conventions to the invoices page
 *
 */

import Spinner from "../Spinner.js";
import { NavLink as Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseUrl } from "../../hooks/BaseUrl.js";
import Animation from "../Animation.jsx";
import { endpoints } from "../../hooks/endpoints.js";
import { getToken } from "../../hooks/utils.js";

const TeacherSales = () => {
  const [invoicesStart, setInvoicesStart] = useState(0);
  const [allInvoices, setAllInvoices] = useState();
  const [invoices, setInvoices] = useState();
  const [cats, setCats] = useState();
  const [currentCat, setCurrentCat] = useState();
  const [groups, setGroups] = useState();
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!invoices) {
      fetch(endpoints.teacherSales, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("sales", data);
          const outputArray = [];
          for (const orderId in data.orders) {
            if (data.orders.hasOwnProperty(orderId)) {
              const order = data.orders[orderId];
              order["order_id"] = orderId;
              outputArray.push(order);
            }
          }
          const rev_arr = outputArray.reverse();
          setAllInvoices(rev_arr);
          setInvoices(rev_arr);
          setGroups(data.cat_lessons);
          setCurrentCat(data.cats);
          setCats(data.cats);
          setIsPending(false);
        })
        .catch((error) => {
          setError(error);
          setIsPending(false);
        });
    }
  }, [invoicesStart]);

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            مبيعاتي
          </h3>
          <select
            onChange={(e) => setCurrentCat(e.target.value)}
            className="form-select w-75 my-2"
            aria-label="Default select example"
          >
            <option value="-" selected>
              القسم
            </option>
            {cats && cats.map((cat) => <option value={cat}>{cat}</option>)}
          </select>
          <select
            onChange={(e) =>
              setInvoices(
                e.target.value === "all"
                  ? allInvoices
                  : allInvoices.filter(
                      (invoice) => invoice.product_id === e.target.value
                    )
              )
            }
            className="form-select w-75 my-2"
            aria-label="Default select example"
          >
            <option value="all" selected>
              كل الحصص
            </option>
            {groups &&
              groups.map(
                (group) =>
                  group.cat === currentCat &&
                  group.lessons.map((lesson) => (
                    <option value={lesson.ID}>{lesson.post_title}</option>
                  ))
              )}
          </select>
          {invoices && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">رقم العملية</th>
                  <th scope="col">الحصة</th>
                  <th scope="col">الطالب</th>
                  <th scope="col">رقم الطالب</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col">الإجمالي</th>
                  <th scope="col">تفاصيل</th>
                </tr>
              </thead>
              <tbody>
                {invoices &&
                  invoices.map(
                    (invoice, index) =>
                      index >= invoicesStart &&
                      index < invoicesStart + 10 && (
                        <tr key={invoice.order_id}>
                          <td scope="row">{invoice.order_id}</td>
                          <td scope="row">
                            {invoice.product_name && invoice.product_name}
                          </td>
                          <td scope="row">{invoice._billing_first_name}</td>
                          <td scope="row">{invoice._billing_phone}</td>
                          <td>{invoice._paid_date}</td>
                          <td>{invoice._order_total} جنيه</td>
                          <td>
                            <Link
                              to={`/invoices/${invoice.order_id}`}
                              style={{ maxHeight: "30px", padding: "3px 8px" }}
                              className="pt-0 btn ea-btn rounded-ea"
                            >
                              تفاصيل
                            </Link>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          )}
          <div className="d-flex">
            {invoices && invoicesStart + 10 < invoices.length && (
              <button
                onClick={() => setInvoicesStart((prev) => prev + 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {invoices && invoicesStart > 0 && (
              <button
                onClick={() => setInvoicesStart((prev) => prev - 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>
          {error && <h2>يجب تسجيل الدخول اولا</h2>}
        </div>
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default TeacherSales;
