import { useEffect, useRef } from "react";
import Spinner from "../Spinner";
import { useState } from "react";
import Animation from "../Animation";
import {
  enAndAr,
  getFileUrl,
  getToken,
  getUID,
  textToUnicode,
} from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

const AccountPage = () => {
  const [passChangeStatus, setPassChangeStatus] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);
  // const [userData, setUserData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const oldPassRef = useRef();
  const newPassRef = useRef();

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setIsPending(true);
    fetch(endpoints.changePassword, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        current_password: oldPassRef.current.value,
        new_password: newPassRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("change_password", data);
        setPassChangeStatus(data);
        setIsPending(false);
      })
      .catch((err) => {
        console.log("change_password", err);
        setPassChangeStatus(err);
        setIsPending(false);
      });
  };

  // useEffect(() => {
  //   /*EliteTransition()*/
  //   fetch(
  //     endpoints.accountPage +
  //       `?timestamp=${Date()}&group=${window.location.hostname}_group`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: getToken(),
  //       },
  //       method: "GET",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setIsPending(false);
  //       setUserData(data);
  //       console.log("userData", data);
  //     })
  //     .catch((err) => {
  //       setIsPending(false);
  //       setError(true);
  //       console.log("userData", err);
  //     });
  // }, []);

  const userData = useSelector((state) => state.store.userProfileData);

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">حسابي</h2>
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {userData && (
            <Animation>
              <div className="m-3 row">
                <div
                  className={`col-md-6 col-12 text-center ${
                    imageLoading && "loading"
                  }`}
                >
                  <img
                    onLoad={() => setImageLoading(false)}
                    style={{
                      aspectRatio: 1,
                      width: "12rem",
                      border: "3px solid var(--primary-color)",
                    }}
                    src={decodeURIComponent(userData.profile_pic)}
                    className="rounded rounded-circle m-3"
                    alt="صورة شخصية"
                  />
                </div>
                <div className="col-md-6 col-12 text-center">
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                    }}
                    className="m-3 qrcode"
                    value={
                      JSON.stringify({
                        name: textToUnicode(userData.display_name),
                        group: textToUnicode(userData.group),
                        uid: getUID(),
                        portal: window.location.hostname,
                      }) ?? "No ID found"
                    }
                    // viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      الإسم رباعي
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.display_name}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.display_name}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المجموعة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.group}
                      aria-label="group"
                      aria-describedby="basic-addon1"
                      value={userData.group}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.user_login}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.user_login}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      إيميل
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.user_email}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.user_email}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون الأب
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.father_phone}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.father_phone}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      تليفون الأم
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.mother_phone}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.mother_phone}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المحافظة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.city}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.city}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      المدرسة
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={userData.school}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userData.school}
                      contentEditable={false}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <p
                    className="text-ea-primary h5 fw-bold mx-auto my-2 rounded-ea p-2"
                    style={{
                      textAlign: "center",
                      width: "50%",
                      minWidth: "150px",
                    }}
                  >
                    تغيير كلمة السر
                  </p>
                </div>
                <form className="row" onSubmit={handlePasswordChange}>
                  <div className="col-md-6 col-12">
                    <div
                      className="container input-group mb-3"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        كلمة السر القديمة
                      </span>
                      <input
                        ref={oldPassRef}
                        type="password"
                        className="form-control"
                        placeholder="كلمة السر القديمة"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div
                      className="container input-group mb-3"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        كلمة السر الجديدة
                      </span>
                      <input
                        ref={newPassRef}
                        type="password"
                        className="form-control"
                        placeholder="كلمة السر الجديدة"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <input
                      style={{ width: "30%", minWidth: "150px" }}
                      className="btn ea-btn rounded-ea mx-auto"
                      type="submit"
                      value="تغيير"
                    />
                  </div>
                  {passChangeStatus && (
                    <div className="d-flex">
                      <div
                        style={{
                          width: "30%",
                          minWidth: "150px",
                          textAlign: "center",
                        }}
                        className={`my-2 mx-auto d-inline alert alert-${
                          passChangeStatus.success ? "success" : "danger"
                        }`}
                        role="alert"
                      >
                        {passChangeStatus.success
                          ? "تم تغيير كلمة السر"
                          : "البيانات خاطئة"}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </Animation>
          )}
          {error && <h2>يجب تسجيل الدخول اولا</h2>}
        </div>
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default AccountPage;
