import React, { useEffect, useState } from "react";
import BankAddQuestion from "./components/BankAddQuestion";
import { handleGetBankCategories } from "./utils";
import FetchQuestions from "./components/FetchQuestions";
import Qimg from "./components/Qimg";
import "./styles.css";
import Separator from "../../Separator";
import AddNewBankCategory from "./components/AddNewBankCategory";
import Animation from "../../Animation";
import { useSelector } from "react-redux";

const QuestionBank = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionsData, setQuestionsData] = useState(null);
  // const [teacherBankCats, setTeacherBankCats] = useState([]);

  // useEffect(() => {
  //   handleGetBankCategories(setTeacherBankCats);
  // }, []);

  const { userProfileData } = useSelector((state) => state.store);

  const teacherBankCats = userProfileData?.bank_categories ?? [];

  useEffect(() => {
    console.log({ questionsData });
  }, [questionsData]);

  return (
    <Animation>
      <div>
        <div className="m-3 p-3 bg-white rounded-ea shadow">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            بنك الأسئلة
          </h3>
          <BankAddQuestion teacherBankCats={teacherBankCats} />
          <Separator />
          <AddNewBankCategory />
          <Separator />
          <FetchQuestions
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            teacherBankCats={teacherBankCats}
            setQuestionsData={setQuestionsData}
          />
          <div className="questions-grid">
            {questionsData &&
              questionsData.map((question) => (
                <Qimg
                  question={question}
                  questionsData={questionsData}
                  setQuestionsData={setQuestionsData}
                  paragraph={question.content}
                  weight={question.weight}
                  src={question.image_url}
                  alt={""}
                  deleteButton={true}
                />
              ))}
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default QuestionBank;
