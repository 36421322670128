import { NavLink as Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NOLESSONS from "../../assets/images/no-lessons-found.svg";
import { getFileUrl } from "../../hooks/utils";

const LessonsMap = () => {
  const noLessons = [];
  const { schoolgrade, group } = useParams();
  const { portal, purchases } = useSelector((state) => state.store);
  const lessons = portal?.data.filter(
    (item) => decodeURIComponent(item.slug) === group
  );

  const isPurchased = (id) => {
    return purchases?.filter
      ? purchases?.filter((purchase) => purchase.lesson_id == id).length > 0
      : false;
  };

  console.log("fasfasdqwf", { schoolgrade, group });

  const formatDate = (dateString) => {
    // Create a Date object from the string
    const date = new Date(dateString.replace(" ", "T")); // Replace space with 'T' for ISO format

    // Format the date to a human-readable format
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
      // second: "2-digit",
      // hour12: true,
    };
    const formattedDate = date.toLocaleString("ar-EG", options);

    return formattedDate; // Output: "July 29, 2023, 19:36:53"
  };

  // lessons.map((lesson) => {
  //   if (lesson.title && !lesson.type) {
  //     noLessons.push(lesson);
  //   }
  //   return noLessons;
  // });

  return (
    <>
      {lessons.length < 1 ? (
        <div className="d-flex">
          <img
            src={NOLESSONS}
            alt="Sorry, no lessons"
            className="mx-auto my-5"
            height="50%"
            width="50%"
          />
        </div>
      ) : (
        lessons.map((lesson) => (
          <Link
            style={{
              textDecoration: "none",
            }}
            to={
              `/${schoolgrade}/${group}/${lesson.post_name}`
              // state: {
              //   teacherData: teacherData,
              //   lesson: lesson,
              //   lessons: noLessons,
              // },
            }
            key={lesson.ID}
          >
            <div className="card mb-3 rounded-ea shadow-sm bg-light">
              <div className="row g-0 d-flex">
                <div
                  className={`col-md-2 bg-${
                    isPurchased(lesson.ID) ? "ea-base" : "half-gray"
                  } rounded-ea d-flex`}
                >
                  <i
                    className={`bi bi-${
                      isPurchased(lesson.ID) ? "play-btn" : "lock"
                    } m-auto text-${
                      isPurchased(lesson.ID) ? "ea-primary" : "dark"
                    } ea-card-icon`}
                  ></i>
                </div>
                <div className="col-md-10 d-flex res-row">
                  <div className="card-body pt-2 pb-1">
                    <h5 className="link-title card-title text-decoration-none mb-1">
                      {lesson.post_title}
                    </h5>
                    <div className="pt-2 pb-2">
                      <p
                        style={{ padding: "2px 10px" }}
                        className={`res-text-sm d-inline text-${
                          isPurchased(lesson.ID) ? "ea-success" : "ea-danger"
                        } fw-bold alert alert-${
                          isPurchased(lesson.ID) ? "success" : "danger"
                        } rounded-ea`}
                        role="alert"
                      >
                        {isPurchased(lesson.ID)
                          ? "تم الشراء"
                          : `${lesson._regular_price} جنيه`}
                      </p>
                    </div>
                    <p className="card-text">
                      <small className="text-muted">
                        {formatDate(lesson.post_date)}
                      </small>
                    </p>
                  </div>
                  <div
                    className="card-body p-1 d-flex"
                    style={{ minWidth: "20%" }}
                  >
                    {/* <div
                          className="m-auto d-inline"
                          style={{ position: "absolute", left: "1%" }}
                        >
                          <div className="text-secondary m-1 p-2 bg-white border-ea rounded-ea">
                            <div className="res-text-sm">{`أيام المشاهدة ${lesson.allowed_time}`}</div>
                          </div>
                          <div className="text-secondary m-1 p-2 bg-white border-ea rounded-ea">
                            <div className="res-text-sm">{`الفيديوهات ${lesson.videos}`}</div>
                          </div>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      )}
    </>
  );
};

export default LessonsMap;
