import React, { useEffect, useRef, useState } from "react";
import Separator from "../Separator";
import Animation from "../Animation";
import { getToken } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { invokePortalRefetch, invokeUserRefetch } from "../../store/slices/storeSlice";

const ManageGroup = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState({ add: false, remove: false });
  const newGroupRef = useRef("");
  const remGroupRef = useRef("");

  const { portal } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  useEffect(() => {
    setGroups(portal?.offline_groups ?? []);
    // fetch(endpoints.getTeacherCats, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: getToken(),
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setGroups([...data]);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [portal]);

  const manageGroup = ({ group, type }) => {
    if (group === "") return alert("أدخل اسم المجموعة");
    setLoading(true);
    if (type === "add" && groups.includes(group.replace(/[\t\n]/g, "")))
      return alert("المجموعة موجودة بالفعل");
    fetch(
      endpoints.manageTeacherOfflineGroups +
        `?group=${group.replace(/[\t\n]/g, "")}&type=${type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setGroups([...data]);
        setLoading(false);
        type === "add"
          ? setTimer({ add: true, remove: false })
          : setTimer({ add: false, remove: true });
        setTimeout(() => {
          setTimer({ add: false, remove: false });
        }, 7000);
        newGroupRef.current.value = "";
        remGroupRef.current.value = "";
        dispatch(invokePortalRefetch())
        dispatch(invokeUserRefetch())
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        newGroupRef.current.value = "";
        remGroupRef.current.value = "";
        alert("حدث خطأ");
      });
  };

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
          إدارة مجاميع الأوفلاين
        </h3>
        <div className="m-3">
          <div className="mb-3">
            <label htmlFor="newGroup" className="h5 form-label">
              إضافة مجموعة جديدة
            </label>
            <input
              ref={newGroupRef}
              type="text"
              className="form-control"
              placeholder="مثال : سنتر إلييت أكاديمي - الإثنين - من ٣ م إلي ٥ م"
              id="newGroup"
            />
          </div>
          <button
            disabled={loading ? true : false}
            onClick={() =>
              manageGroup({
                group: newGroupRef.current.value,
                type: "add",
              })
            }
            className="btn ea-btn rounded-ea"
            type="button"
          >
            إضافة
          </button>
          {timer.add && (
            <div
              className="alert alert-success rounded-ea my-2"
              style={{ width: "fit-content" }}
              role="alert"
            >
              تم إضافة المجموعة بنجاح
            </div>
          )}
        </div>
        <Separator />
        <div className="m-3">
          <div className="mb-3">
            <label htmlFor="newGroup" className="h5 form-label">
              إزالة المجموعة
            </label>
            <select
              ref={remGroupRef}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected>اختر المجموعة</option>
              {groups.map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn ea-btn rounded-ea"
            type="button"
            disabled={loading ? true : false}
            onClick={() =>
              manageGroup({
                group: remGroupRef.current.value,
                type: "remove",
              })
            }
          >
            إزالة
          </button>
          {timer.remove && (
            <div
              className="alert alert-success rounded-ea my-2"
              style={{ width: "fit-content" }}
              role="alert"
            >
              تم إزالة المجموعة بنجاح
            </div>
          )}
        </div>
      </div>
    </Animation>
  );
};

export default ManageGroup;
