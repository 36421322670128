// import useAuth from "../../../hooks/useAuth";
import LoginForm from "../LoginForm";
import RegistrationForm from "../RegistrationForm";
import HomeCarousel from "../../../scripts/typeWriter";
import { useSelector } from "react-redux";
import { getFileUrl } from "../../../hooks/utils";

const GeneralTeacherTemp = () => {
  const { user, portal } = useSelector((state) => state.store);

  return (
    <div className="w-100 bg-white pb-5">
      <div className="bg-ea-primary" style={{ height: "5px" }}></div>
      <div className="row mb-5">
        {portal?.bgImg && (
          <div
            className="col-md-6 responsive-bg text-center"
            style={{
              // backgroundImage: `url(${portal?.bgImg})`,
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "center",
              // backgroundSize: "contain",
              maxHeight: "70vh",
            }}
          >
            <img src={decodeURIComponent(portal?.bgImg ?? '')} className="h-100" />
          </div>
        )}
        <div className="col-md-6">
          <div
            style={{
              justifyContent: "left",
              display: "flex",
              margin: "10px 5% 0 5%",
            }}
          >
            <HomeCarousel />
          </div>
          <div
            style={{
              justifyContent: "left",
              display: "flex",
              marginLeft: "5%",
            }}
          >
            <div className="m-5 rounded-ea bg-white w-75" id="loginFormCol">
              {!user && (
                <>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                      <button
                        className="nav-link ea-toggle active text-ea-primary w-100"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        style={{
                          borderRadius: "0px 10px 0px 15px",
                        }}
                      >
                        تسجيل دخول
                      </button>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                      <button
                        className="nav-link ea-toggle text-ea-primary w-100"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        style={{
                          borderRadius: "10px 0px 15px 0px",
                        }}
                      >
                        انشاء حساب
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content mt-0" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <LoginForm />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <RegistrationForm />
                    </div>
                  </div>
                </>
              )}
              {user && <div>أنت عملت تسجيل دخول</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="container fr-home-blocks-container">
        <div className="row mb-2">
          <div className="col-md-4 d-flex my-1">
            <div className="bg-white m-auto p-2 d-flex fr-home-blocks">
              <div className="w-25 d-flex">
                <i
                  className="bi bi-person-vcard text-ea-primary m-auto"
                  style={{ fontSize: "3.5rem" }}
                ></i>
              </div>
              <div className="w-75">
                <p className="h5 mx-2 my-1 fw-bold">لوحة تحكم لكل طالب</p>
                <p className="mx-2 my-1">
                  يحصل كل طالب على لوحة تحكم يتابع منها تطورات المادة
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex my-1">
            <div className="bg-white m-auto p-2 d-flex fr-home-blocks">
              <div className="w-25 d-flex">
                <i
                  className="bi bi-file-text text-ea-primary m-auto"
                  style={{ fontSize: "3.5rem" }}
                ></i>
              </div>
              <div className="w-75">
                <p className="h5 mx-2 my-1 fw-bold">إمتحان مخصص لكل طالب</p>
                <p className="mx-2 my-1">
                  يحصل كل طالب على إمتحان مصمم خصيصاً له لكي يطور من مهاراته
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex my-1">
            <div className="bg-white m-auto p-2 d-flex fr-home-blocks">
              <div className="w-25 d-flex">
                <i
                  className="bi bi-clipboard-data text-ea-primary m-auto"
                  style={{ fontSize: "3.5rem" }}
                ></i>
              </div>
              <div className="w-75">
                <p className="h5 mx-2 my-1 fw-bold">مراقبة لأداء الطالب</p>
                <p className="mx-2 my-1">
                  يتم مراقبة أداء الطالب بإستخدام نظام ذكي يححد نقاط ضعف كل طالب
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralTeacherTemp;
