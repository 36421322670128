import { useEffect, useState } from "react";
import { fetchQuestions, handleGetBankCategories } from "../QuestionBank/utils";
import Qimg from "../QuestionBank/components/Qimg";
import { useSelector } from "react-redux";

const FetchQuestionsInQuiz = ({ addQuestion, questionNum }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionsData, setQuestionsData] = useState(null);
  // const [teacherBankCats, setTeacherBankCats] = useState([]);
  const [chosenCat, setChosenCat] = useState(null);

  // useEffect(() => {
  //   handleGetBankCategories(setTeacherBankCats);
  // }, []);

  const { userProfileData } = useSelector((state) => state.store);

  const teacherBankCats = userProfileData?.bank_categories ?? [];

  return (
    <div>
      <div className="p-3">
        <select
          className="form-select mb-3 rounded-ea"
          value={chosenCat}
          onChange={(e) => setChosenCat(e.target.value)}
          required
        >
          <option>إختر القسم</option>
          {teacherBankCats.map((ans) => (
            <option value={ans}>{ans}</option>
          ))}
        </select>
        {!isLoading && (
          <input
            type="submit"
            disabled={isLoading}
            className="ea-btn btn w-100 rounded-ea"
            value="بحث"
            onClick={(e) => {
              e.preventDefault();
              fetchQuestions({
                cat: chosenCat,
                setQuestionsData,
                setIsLoading,
              });
            }}
          />
        )}
        {isLoading && (
          <button
            className="ea-btn btn w-100 rounded-ea"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            جاري البحث...
          </button>
        )}
      </div>
      <div className="my-2">
        {questionsData &&
          questionsData.map((question) => (
            <div
              className="clickable-question-card my-2"
              onClick={() => {
                addQuestion(question, questionNum);
                const imgBtn = document.getElementById(
                  `acc-btn-${questionNum}`
                );
                imgBtn.click();
              }}
            >
              <Qimg
                paragraph={question.content}
                weight={question.weight}
                src={question.image_url}
                alt={""}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FetchQuestionsInQuiz;
