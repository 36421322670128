import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LessonCountdown from "../LessonCountdown.js";
import Quiz from "../../quiz/Quiz.js";
import VideosMap from "../../video/VideosMap.js";
import useFetch from "../../../hooks/useFetch.js";
import Spinner from "../../Spinner.js";
import Purchase from "../../orders/Purchase.js";
import Homework from "../../quiz/Homework.js";
import devtools from "devtools-detect";
import { baseUrl } from "../../../hooks/BaseUrl.js";
import { handleFreePurchase } from "../../orders/purchase_methods/FreePurchase.js";
// import useAuth from "../../../hooks/useAuth.js";
import useInfoRequired from "../../useInfoRequired.js";
import InfoRequired from "../../InfoRequired.js";
import Sidebar from "./components/Sidebar.js";
import { checkUnansweredQuestions } from "./utils/checkUnansweredQuestions";
import useCheckIsMobile from "./utils/useCheckIsMobile";
import LessonContent from "./components/LessonContent.js";
import { isDevOpen } from "./utils/isDevToolsOpen.js";
import Animation from "../../Animation.jsx";
import { endpoints } from "../../../hooks/endpoints.js";
import { getToken } from "../../../hooks/utils.js";
import { useDispatch, useSelector } from "react-redux";
import { invokeUserRefetch } from "../../../store/slices/storeSlice.js";

const LessonDetails = () => {
  const { lesson } = useParams();
  const [devIsOpen, setDevIsOpen] = useState(false);
  const [quizRequired, setQuizRequired] = useState(1);
  const [lessonData, setData] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [notFinishedPrereq, setNotFinishedPrereq] = useState(false);
  const [pastLastPurchaseDate, setPastLastPurchaseDate] = useState(false);
  const [currentContent, setCurrentContent] = useState("quiz"); // 'quiz' || 'video' || 'hw'
  // const { isAuthenticated, isPending: authPending } = useAuth();
  const { user, userData } = useSelector((state) => state.store);
  const isAuthenticated = user;
  const authPending = !user;
  const dispatch = useDispatch();

  // const { infoRequired, setInfoRequired } = useInfoRequired();

  const domain = window.location.hostname;

  // const { data: lessons } = useFetch(
  //   `${endpoints.sidebarLessons}teacher=${teacherSlug}`
  // );

  useEffect(() => {
    /*EliteTransition()*/ setNotFinishedPrereq(false);
    setIsPending(true);
    fetch(endpoints.lesson + `?timestamp=${Date()}&lesson=${lesson}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res) {
          alert("Could not fetch the data");
          throw Error("Could not fetch the data");
        }
        setData(res ?? false);
        setIsPending(false);
        const currentTimestamp = Date.now();
        let expiryTimestamp;
        try {
          expiryTimestamp = Date.parse(res.last_purchase_date);
        } catch (e) {
          expiryTimestamp = null;
        }
        if (res.last_purchase_date && currentTimestamp > expiryTimestamp) {
          setPastLastPurchaseDate(true);
        }
        if (!res.hw_raw_data && res.pre) {
          setNotFinishedPrereq(true);
        } else {
          setNotFinishedPrereq(
            checkUnansweredQuestions(() => {
              try {
                return JSON.parse(res.hw_raw_data);
              } catch (e) {
                return null;
              }
            })
          );
        }
        // console.log("hw", res.hw_raw_data)
      })
      .catch((err) => {
        console.log("dsaFc", err);
        setIsPending(false);
        setError(err);
      });
  }, [lesson]);

  useEffect(() => {
    isDevOpen(setDevIsOpen);
    window.addEventListener("resize", function () {
      isDevOpen(setDevIsOpen);
    });
    return () =>
      window.removeEventListener("resize", function () {
        isDevOpen(setDevIsOpen);
      });
  }, []);

  const { isMobile } = useCheckIsMobile();

  if (
    userData &&
    userData.disabled_sites &&
    userData.disabled_sites.filter(
      (site) => site.toString() === window.location.hostname.toString()
    ).length > 0
  ) {
    return (
      <div className="p-2">
        <div
          className="alert alert-danger fw-bold rounded-ea w-100"
          role="alert"
        >
          قام المدرس بمنعك من دخول الحصص , تواصل مع المدرس
        </div>
      </div>
    );
  } else if (isAuthenticated && !authPending) {
    return (
      <Animation>
        <div className="lesson-page responsive-lesson bg-eee">
          <div className="row bg-eee d-flex">
            <div className="col-md-8 col-12 p-3">
              {lessonData && !isPending ? (
                <div className="bg-white rounded-ea shadow pb-4">
                  <div className="pt-4 pe-4 ps-4 pb-2">
                    <div className="row">
                      {lessonData && lessonData.newly_purchased && (
                        <div
                          className="alert alert-success rounded-ea w-100"
                          role="alert"
                        >
                          تم شراء الحصة بنجاح
                        </div>
                      )}
                      <div className="col-md-8 col-12">
                        <h3 className="my-auto fw-bold text-333">
                          {lessonData && lessonData.title}
                        </h3>
                      </div>
                      <div className="col-md-4 col-12 d-flex justify-content-end align-middle">
                        {/* <p className="my-auto">
                      تاريخ النشر : {lessonData && lessonData.date}
                    </p> */}
                      </div>
                    </div>
                    {lessonData &&
                      lessonData.price === null &&
                      lessonData.price !== 0 &&
                      !lessonData.is_author && (
                        <div className="d-flex row">
                          {lessonData.expiry_date !== "infinite" && (
                            <div className="res-timers col-md-6 col-12 m-auto d-inline mt-2 py-1 px-auto alert alert-danger rounded-ea mb-0">
                              {lessonData && (
                                <Animation>
                                  <LessonCountdown
                                    targetDate={lessonData.expiry_date}
                                  />
                                </Animation>
                              )}
                            </div>
                          )}
                          <div className="res-timers col-md-6 col-12 d-inline mx-auto mt-2 py-1 px-auto bg-ea-base-2 border-ea rounded-ea text-ea-primary">
                            {lessonData &&
                              `مرات المشاهدة المتبقية (${
                                lessonData.remaining_views === "infinite"
                                  ? "غير محدود"
                                  : lessonData.remaining_views < 1
                                  ? "نفذت المشاهدات"
                                  : lessonData.remaining_views
                              })`}
                          </div>
                        </div>
                      )}
                  </div>
                  {lessonData &&
                    lessonData.price === null &&
                    lessonData.price !== 0 &&
                    lessonData.video_exist && (
                      <Animation>
                        <ul className="nav nav-pills w-100 res-tabs px-4">
                          <li
                            className="nav-item m-auto my-1"
                            role="presentation"
                            style={{ width: "32%" }}
                          >
                            <button
                              style={{ border: "2px solid #673DE6" }}
                              className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
                                currentContent === "quiz" && "active"
                              }`}
                              type="button"
                              onClick={() => setCurrentContent("quiz")}
                            >
                              كويز
                            </button>
                          </li>
                          {lessonData.quiz_required === 0 ||
                          quizRequired === 0 ||
                          lessonData.past_quiz_trials !== null ? (
                            <li
                              className="nav-item m-auto my-1"
                              role="presentation"
                              style={{ width: "32%" }}
                            >
                              <button
                                style={{
                                  border: "2px solid #673DE6",
                                }}
                                className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
                                  currentContent === "video" && "active"
                                }`}
                                type="button"
                                onClick={() => setCurrentContent("video")}
                              >
                                فيديو
                              </button>
                            </li>
                          ) : (
                            <li
                              className="nav-item m-auto my-1"
                              role="presentation"
                              style={{ width: "32%" }}
                            >
                              <button
                                disabled={true}
                                style={{
                                  border: "2px solid #ddd",
                                  backgroundColor: "#ddd",
                                }}
                                className="nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2"
                                type="button"
                              >
                                فيديو ( يجب حل الكويز )
                              </button>
                            </li>
                          )}
                          <li
                            className="nav-item m-auto my-1"
                            role="presentation"
                            style={{ width: "32%" }}
                          >
                            <button
                              style={{ border: "2px solid #673DE6" }}
                              className={`nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 ${
                                currentContent === "hw" && "active"
                              }`}
                              type="button"
                              onClick={() => setCurrentContent("hw")}
                            >
                              واجب
                            </button>
                          </li>
                        </ul>
                      </Animation>
                    )}
                  <Animation>
                    <LessonContent
                      lessonData={lessonData}
                      quizRequired={quizRequired}
                      setQuizRequired={setQuizRequired}
                      devIsOpen={devIsOpen}
                      setDevIsOpen={setDevIsOpen}
                      isMobile={isMobile}
                      currentContent={currentContent}
                    />
                  </Animation>
                  {lessonData &&
                    lessonData.price !== null &&
                    !notFinishedPrereq &&
                    !pastLastPurchaseDate &&
                    lessonData.price !== 0 && (
                      <Animation>
                        <Purchase
                          lessonData={lessonData}
                          setData={setData}
                          isMobile={isMobile}
                        />
                      </Animation>
                    )}
                  {lessonData &&
                    lessonData.price !== null &&
                    lessonData.price === 0 && (
                      <Animation>
                        <div className="d-flex">
                          <button
                            onClick={async () => {
                              await handleFreePurchase({
                                setData,
                                setError,
                                setIsPending,
                                lesson_id: lessonData.lesson_id,
                              });
                              dispatch(invokeUserRefetch());
                            }}
                            className="mx-auto my-3 btn ea-btn rounded-ea"
                          >
                            الإشتراك فالحصة مجاناً
                          </button>
                        </div>
                      </Animation>
                    )}
                  {lessonData &&
                    notFinishedPrereq &&
                    lessonData.price !== 0 &&
                    !lessonData.last_purchase_date && (
                      <Animation>
                        <div
                          className="alert alert-danger m-5 rounded-ea"
                          role="alert"
                        >
                          يجب إنهاء واجب الحصة السابقة , إذا كانت هذه الحصة
                          الأولى لك مع المدرس برجاء التواصل مع المدرس
                        </div>
                      </Animation>
                    )}
                  {lessonData &&
                    lessonData.price !== 0 &&
                    pastLastPurchaseDate && (
                      <Animation>
                        <div
                          className="alert alert-danger m-5 rounded-ea"
                          role="alert"
                        >
                          إنتهى الوقت المسموح لشراء الحصة , تواصل مع المدرس
                        </div>
                      </Animation>
                    )}
                </div>
              ) : error && !isPending ? (
                <h3>يجب تسجيل الدخول اولا</h3>
              ) : (
                <Spinner />
              )}
            </div>
            <div className="col-md-4 col-12 pt-3 mt-0">
              <Animation>
                <Sidebar
                // lessons={lessons}
                // lessonData={lessonData}
                // teacherSlug={teacherSlug}
                // lessonSlug={lesson}
                // setIsPending={setIsPending}
                />
              </Animation>
            </div>
          </div>
        </div>
      </Animation>
    );
  } else if (!isAuthenticated && authPending) {
    return (
      <Animation>
        <Spinner />
      </Animation>
    );
  } else {
    return (
      <div className="p-2">
        <div
          className="alert alert-danger fw-bold rounded-ea w-100"
          role="alert"
        >
          يجب تسجيل الدخول أولاً , إضغط{" "}
          <Link to="/" className="px-2 py-0 mx-1 fw-bold btn ea-btn">
            هنا
          </Link>{" "}
          للذهاب للصفحة الرئيسية
        </div>
      </div>
    );
  }
};

export default LessonDetails;
