import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../hooks/BaseUrl";
import Separator from "../Separator";
import Animation from "../Animation";
import { getToken } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  invokePortalRefetch,
  invokeUserRefetch,
} from "../../store/slices/storeSlice";

const ManageGrades = () => {
  const [cats, setCats] = useState([]);
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [timer, setTimer] = useState({ add: false, remove: false });
  const newCatRef = useRef("");
  const remCatRef = useRef("");
  const gradeRef = useRef("");
  const dispatch = useDispatch();

  const { userProfileData, portal } = useSelector((state) => state.store);

  useEffect(() => {
    setCats(portal?.cats?.filter((item) => item.parent !== 0) ?? []);
    setGrades(portal?.cats?.filter((item) => item.parent === 0) ?? []);
    // fetch(endpoints.getTeacherCats, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: getToken(),
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setCats([...data]);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [userProfileData, portal]);

  const manageCat = ({ cat, type }) => {
    if (cat === "") return alert("أدخل اسم القسم");
    setLoading(true);
    if (type === "add" && cats.includes(cat.replace(/[\t\n]/g, "")))
      return alert("القسم موجود بالفعل");
    fetch(
      endpoints.manageTeacherCats +
        `?cat_id=${cat}&cat=${cat.replace(/[\t\n]/g, "")}&grade=${
          gradeRef?.current?.value
        }&type=${type}&portal=${window.location.hostname}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (!data?.status) {
          setErrMsg(data?.data);
          setTimeout(() => {
            setErrMsg(null);
          }, 7000);
          return;
        }
        setCats([...data?.data]);
        type === "add"
          ? setTimer({ add: true, remove: false })
          : setTimer({ add: false, remove: true });
        setTimeout(() => {
          setTimer({ add: false, remove: false });
        }, 7000);
        gradeRef.current.value = "";
        newCatRef.current.value = "";
        remCatRef.current.value = "";
        dispatch(invokePortalRefetch());
        dispatch(invokeUserRefetch());
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        gradeRef.current.value = "";
        newCatRef.current.value = "";
        remCatRef.current.value = "";
        dispatch(invokePortalRefetch());
        dispatch(invokeUserRefetch());
        alert("حدث خطأ");
      });
  };

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
          إدارة الأقسام
        </h3>
        <div className="m-3">
          <h3 className="mb-3">إضافة الأقسام جديدة</h3>
          <div className="mb-3">
            <select
              ref={gradeRef}
              className="form-select"
              aria-label="Default select example"
              required
            >
              <option value="" selected>
                إختر المرحلة
              </option>
              {grades.map((grade, index) => (
                <option key={index} value={grade.term_id}>
                  {grade?.name?.indexOf("|") === -1
                    ? grade?.name
                    : grade?.name?.split("|")[0]}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            {/* <label htmlFor="newCat" className="h5 form-label">
              إضافة قسم جديد
            </label> */}
            <input
              ref={newCatRef}
              type="text"
              className="form-control"
              placeholder="أدخل اسم القسم الجديد"
              id="newCat"
            />
          </div>
          <button
            disabled={loading ? true : false}
            onClick={() =>
              manageCat({
                cat: newCatRef.current.value,
                type: "add",
              })
            }
            className="btn ea-btn rounded-ea"
            type="button"
          >
            إضافة
          </button>
          {timer.add && (
            <div
              className="alert alert-success rounded-ea my-2"
              style={{ width: "fit-content" }}
              role="alert"
            >
              تم إضافة القسم بنجاح
            </div>
          )}
          {errMsg && (
            <div
              className="alert alert-danger rounded-ea my-2"
              style={{ width: "fit-content" }}
              role="alert"
            >
              {errMsg}
            </div>
          )}
        </div>
        <Separator />
        <div className="m-3">
          <div className="mb-3">
            <label htmlFor="newCat" className="h5 form-label">
              إزالة قسم
            </label>
            <select
              ref={remCatRef}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected>اختر القسم</option>
              {cats.map((cat) => (
                <option key={cat.term_taxonomy_id} value={cat.term_taxonomy_id}>
                  {cat.name?.indexOf("|") !== -1
                    ? cat.name?.split("|")[0]
                    : cat.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn ea-btn rounded-ea"
            type="button"
            disabled={loading ? true : false}
            onClick={() =>
              manageCat({
                cat: remCatRef.current.value,
                type: "remove",
              })
            }
          >
            إزالة
          </button>
          {timer.remove && (
            <div
              className="alert alert-success rounded-ea my-2"
              style={{ width: "fit-content" }}
              role="alert"
            >
              تم إزالة القسم بنجاح
            </div>
          )}
        </div>
      </div>
    </Animation>
  );
};

export default ManageGrades;
