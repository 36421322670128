import { useEffect, useState } from "react";
import { useRef } from "react";
import { baseUrl } from "../../hooks/BaseUrl";
import QuestionImg from "./QuestionImg";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../hooks/endpoints";
import { invokeUserRefetch } from "../../store/slices/storeSlice";
import { getFileUrl } from "../../hooks/utils";

const Homework = ({ lessonData }) => {
  const { currentTeacher } = useSelector((state) => state.store);
  const [startQuiz, setStartQuiz] = useState(false);
  const [quizResult, setQuizResult] = useState();
  const [quizScore, setQuizScore] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState({});
  const quizForm = useRef();
  const scoreDiv = useRef();
  const user = JSON.parse(
    localStorage.getItem(`${window.location.hostname}_user`)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("dwqfsafdqw", quizResult);
  }, [quizResult]);

  const handleStartQuiz = () => {
    fetch(`${endpoints.hwProgress}?hwid=${lessonData.hw_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result !== "" && !localStorage.getItem(lessonData.hw_id)) {
          setQuizResult(JSON.parse(result));
          setPercentage(
            (Number(JSON.parse(result).score) /
              Number(JSON.parse(result).maxScore)) *
              100
          );
          setQuizScore(
            `${JSON.parse(result).score} / ${JSON.parse(result).maxScore}`
          );
        }
        console.log(result);
        const questionsAnswers = {};
        try {
          lessonData?.hw_questions?.forEach((item) => {
            questionsAnswers[item.question_id] = item.correct_answer;
          });
          setQuestionsAnswers(questionsAnswers);
        } catch (e) {
          setQuestionsAnswers(questionsAnswers);
        }
      })
      .catch((error) => console.log(error));
    setStartQuiz(true);
  };

  useEffect(() => {
    if (lessonData.hw_id && !localStorage.getItem(lessonData.hw_id)) {
      handleStartQuiz();
    } else if (lessonData.hw_id && localStorage.getItem(lessonData.hw_id)) {
      setStartQuiz(true);
    }
  }, []);

  const handleSubmitQuiz = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const formData = new FormData(quizForm.current);
    const formDataObject = {};
    for (const [key, value] of formData.entries()) {
      formDataObject[key] = value;
    }
    formDataObject["hw_id"] = lessonData.hw_id;
    const qResult = { score: 0, maxScore: 0 };
    lessonData.hw_questions.map((question) => {
      if (formDataObject[question.question_id] === question.correct_answer) {
        qResult[question.question_id] = {
          correct: true,
          answer: formDataObject[question.question_id],
        };
        qResult["score"] = Number(qResult["score"]) + Number(question.weight);
      } else {
        qResult[question.question_id] = {
          correct: false,
          answer: formDataObject[question.question_id],
        };
      }
      qResult["maxScore"] =
        Number(qResult["maxScore"]) + Number(question.weight);
    });
    // setPercentage((Number(qResult.score) / Number(qResult.maxScore)) * 100);
    // setQuizResult(qResult);
    // setQuizScore(`${qResult.score} / ${qResult.maxScore}`);
    // scoreDiv.current.scrollIntoView();
    // console.log(qResult)
    // const {score, maxScore, ...answers} = qResult
    const answers = [];
    lessonData.hw_questions.map((question) => {
      answers.push(
        `${question.question_id}-${qResult[question.question_id].answer}-${
          qResult[question.question_id].correct
        }`
      );
    });
    // console.log("answers",answers)
    fetch(endpoints.submitHomework, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        title: `${lessonData.hw_id}-${user.user_display_name}`,
        homework_id: lessonData.hw_id,
        homework: lessonData.hw_id,
        grade: qResult.score,
        raw_data: JSON.stringify(qResult),
        answers: answers.join(","),
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message === "success") {
          setPercentage(
            (Number(qResult.score) / Number(qResult.maxScore)) * 100
          );
          setQuizResult(qResult);
          setQuizScore(`${qResult.score} / ${qResult.maxScore}`);
          scoreDiv.current.scrollIntoView();
          setLoading(false);
          localStorage.removeItem(lessonData.hw_id);
          dispatch(invokeUserRefetch());
        } else {
          setSubmitError(true);
          setLoading(false);
        }
        console.log(result);
      })
      .catch((error) => {
        setLoading(false);
        setSubmitError(true);
        console.log(error);
      });
  };

  return (
    <div>
      {lessonData.hw_id && !quizResult && (
        <div className="alert alert-info mt-2 mx-3 rounded-ea" role="alert">
          يتم حفظ الأجابات تلقائياً , يمكنك العودة لإكمال للواجب في أي وقت
        </div>
      )}
      <div ref={scoreDiv}></div>
      {quizResult && (
        <div className="p-3">
          <div className="d-flex">
            <img
              className="image-fluid w-50 mx-auto"
              src="https://api.eliteacademyeg.com/wp-content/uploads/2024/01/exam-finished-1.png"
              alt=""
            />
          </div>
          <div className="d-flex mb-3">
            <h3 className="m-auto">درجتك في الواجب</h3>
          </div>
          <div className="d-flex">
            <div
              className="progress rounded-ea responsive-quiz-grade mx-auto"
              style={{ height: "1.5rem" }}
            >
              <div
                className="progress-bar rounded-ea"
                role="progressbar"
                style={{ width: `${percentage}%`, height: "1.5rem" }}
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <p className="fw-bold my-auto fs-5">{quizScore}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!startQuiz && lessonData.hw_id ? (
        <div className="p-5">
          <div className="w-100 d-flex">
            <button
              className="btn ea-btn m-auto rounded-ea px-4 py-2"
              onClick={handleStartQuiz}
            >
              إبدأ واجب
            </button>
          </div>
        </div>
      ) : !startQuiz && !lessonData.hw_id ? (
        <div className="p-5 d-flex">
          {currentTeacher === "mr-hazem-el-kashif" ||
          window.location.hostname.includes("kashif") ? (
            <h2 className="m-auto">الواجب في الWorkbook</h2>
          ) : (
            <h2 className="m-auto">لا يوجد واجب</h2>
          )}
        </div>
      ) : (
        ""
      )}
      {startQuiz && (
        <>
          <form
            ref={quizForm}
            className="quiz-form"
            onSubmit={(e) => handleSubmitQuiz(e)}
          >
            {lessonData &&
              lessonData.hw_questions.map((question) => (
                <div
                  key={question.question_id}
                  className={`p-2 mt-3 bg-light mb-3 mx-4 border-ea-2 rounded-ea ${
                    quizResult && quizResult[question.question_id].answer
                      ? "quiz-ended"
                      : ""
                  }`}
                >
                  <div className="rounded-ea d-flex">
                    {/* <img
                      style={{maxHeight:'350px'}}
                      src={lessonData && question.img}
                      className="m-auto rounded-3 w-100"
                      alt={lessonData && question.title}
                    /> */}
                    {lessonData && question.img && (
                      <QuestionImg
                        src={lessonData && question.img}
                        alt={lessonData && question.title}
                      />
                    )}
                  </div>
                  {lessonData && question.content ? (
                    <p className="my-2" style={{ textAlign: "center" }}>
                      {question.content}
                    </p>
                  ) : (
                    ""
                  )}
                  {/*  */}

                  <div
                    className="my-2 btn-group w-100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {question.answers.map((answer, index) => (
                      <>
                        <input
                          onChange={(e) => {
                            let currentAnswers = {};
                            if (localStorage.getItem(lessonData.hw_id)) {
                              currentAnswers = JSON.parse(
                                localStorage.getItem(lessonData.hw_id)
                              );
                            }
                            localStorage.setItem(
                              lessonData.hw_id,
                              JSON.stringify({
                                ...currentAnswers,
                                [question.question_id]: e.target.value,
                              })
                            );
                            console.log(e.target.value);
                          }}
                          type="radio"
                          className="btn-check"
                          name={question.question_id}
                          id={`btnradio-${question.title}-${
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }`}
                          value={
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }
                          autoComplete="off"
                          required={
                            lessonData.must_answer_all === "on" ? true : false
                          }
                          checked={
                            (localStorage.getItem(lessonData.hw_id) &&
                              JSON.parse(
                                localStorage.getItem(lessonData.hw_id)
                              )[question.question_id] ===
                                question.answers[
                                  question.answers.length - (index + 1)
                                ]) ||
                            (quizResult &&
                              quizResult[question.question_id].answer ===
                                question.answers[
                                  question.answers.length - (index + 1)
                                ])
                              ? true
                              : null
                          }
                        />
                        <label
                          className="btn btn-outline-primary fw-bold"
                          htmlFor={`btnradio-${question.title}-${
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }`}
                        >
                          {lessonData &&
                            question.answers[
                              question.answers.length - (index + 1)
                            ]}
                        </label>
                      </>
                    ))}
                  </div>
                  {quizResult && quizResult[question.question_id].correct && (
                    <div className="d-flex">
                      <div
                        style={{ maxHeight: "50px" }}
                        className="d-flex py-2 px-4 rounded-ea alert alert-success mx-auto"
                        role="alert"
                      >
                        <p className="fw-bold m-auto">إجابة صحيحة</p>
                      </div>
                    </div>
                  )}
                  {quizResult &&
                    !quizResult[question.question_id].correct &&
                    quizResult[question.question_id].answer && (
                      <div className="d-flex">
                        <div
                          style={{ maxHeight: "50px" }}
                          className="d-felx py-2 px-4 rounded-ea alert alert-danger mx-auto"
                          role="alert"
                        >
                          {/* <p className="fw-bold m-auto">إجابة خاطئة</p> */}
                          <p className="fw-bold m-auto">
                            {lessonData?.hide_correct_answers === "on"
                              ? "إجابة خاطئة"
                              : `الإجابة الصحيحة هي ${
                                  questionsAnswers?.[question?.question_id]
                                }`}
                          </p>
                        </div>
                      </div>
                    )}
                  {/*  */}
                  {/* <div
                    className="my-2 btn-group w-100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name={question.question_id}
                      id={`btnradio-${question.title}-${question.answers[3]}`}
                      value={question.answers[3]}
                      autoComplete="off"
                      checked={
                        quizResult &&
                        quizResult[question.question_id].answer ===
                          question.answers[3]
                          ? true
                          : null
                      }
                      // required={lessonData.must_answer_all === 'on' ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary fw-bold"
                      htmlFor={`btnradio-${question.title}-${question.answers[3]}`}
                    >
                      {lessonData && question.answers[3]}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name={question.question_id}
                      id={`btnradio-${question.title}-${question.answers[2]}`}
                      value={question.answers[2]}
                      autoComplete="off"
                      checked={
                        quizResult &&
                        quizResult[question.question_id].answer ===
                          question.answers[2]
                          ? true
                          : null
                      }
                      // required={lessonData.must_answer_all === 'on' ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary fw-bold"
                      htmlFor={`btnradio-${question.title}-${question.answers[2]}`}
                    >
                      {lessonData && question.answers[2]}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name={question.question_id}
                      id={`btnradio-${question.title}-${question.answers[1]}`}
                      value={question.answers[1]}
                      autoComplete="off"
                      checked={
                        quizResult &&
                        quizResult[question.question_id].answer ===
                          question.answers[1]
                          ? true
                          : null
                      }
                      // required={lessonData.must_answer_all === 'on' ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary fw-bold"
                      htmlFor={`btnradio-${question.title}-${question.answers[1]}`}
                    >
                      {lessonData && question.answers[1]}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name={question.question_id}
                      id={`btnradio-${question.title}-${question.answers[0]}`}
                      value={question.answers[0]}
                      autoComplete="off"
                      checked={
                        quizResult &&
                        quizResult[question.question_id].answer ===
                          question.answers[0]
                          ? true
                          : null
                      }
                      // required={lessonData.must_answer_all === 'on' ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary fw-bold"
                      htmlFor={`btnradio-${question.title}-${question.answers[0]}`}
                    >
                      {lessonData && question.answers[0]}
                    </label>
                  </div> */}
                </div>
              ))}
            <div className="d-flex py-2 px-3">
              {!loading && (
                <input
                  disabled={quizScore ? true : false}
                  type="submit"
                  className="btn ea-btn rounded-ea w-100 mx-auto"
                  value="حفظ الإجابات"
                />
              )}
              {loading && (
                <button
                  className="btn ea-btn rounded-ea w-100 mx-auto"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  حفظ الإجابات...
                </button>
              )}
            </div>
            {submitError && (
              <div className="alert alert-danger mx-3 rounded-ea" role="alert">
                حدث خطأ أثناء حفظ الإجابات , تواصل مع الدعم الفني
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default Homework;
