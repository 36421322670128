import React, { useEffect, useRef, useState } from "react";
import useFetch from "../hooks/useFetch";
import Spinner from "./Spinner";
import { getToken, isNameValid, isPhoneValid } from "../hooks/utils";
import { endpoints } from "../hooks/endpoints";

const InfoRequired = ({ setInfoRequired }) => {
  const [pending, setPending] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [userData, setUserData] = useState(false);
  const name = useRef();
  const email = useRef();
  const phone = useRef();
  const fphone = useRef();
  const mphone = useRef();
  const city = useRef();
  const school = useRef();

  useEffect(() => {
    setIsPending(true);
    fetch(endpoints.accountPage, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("cewfsafq", data);
        setIsPending(false);
        setUserData(data);
        if (name.current) name.current.value = data.billing_first_name[0];
        if (phone.current) phone.current.value = data.billing_phone[0];
        if (email.current) email.current.value = data.billing_email[0];
      })
      .catch((err) => {
        setIsPending(false);
        setError("حدث خطأ");
      });
  }, []);

  const handleSubmitInfo = (e) => {
    e.preventDefault();

    // Check name
    const checkName = isNameValid(name.current.value);
    if (!checkName.isValid) {
      name.current.style = "border: 1px solid red";
      return setErrMsg(checkName.message);
    } else {
      name.current.style = "";
    }

    // Check student phone
    const checkPhone = isPhoneValid(phone.current.value);
    if (!checkPhone.isValid) {
      phone.current.style = "border: 1px solid red";
      return setErrMsg(checkPhone.message);
    } else {
      phone.current.style = "";
    }

    // Check father phone
    const checkFPhone = isPhoneValid(fphone.current.value);
    if (!checkFPhone.isValid) {
      fphone.current.style = "border: 1px solid red";
      return setErrMsg(checkFPhone.message);
    } else {
      fphone.current.style = "";
    }

    // Check mother phone
    const checkMPhone = isPhoneValid(mphone.current.value);
    if (!checkMPhone.isValid) {
      mphone.current.style = "border: 1px solid red";
      return setErrMsg(checkMPhone.message);
    } else {
      mphone.current.style = "";
    }

    setErrMsg(null);
    setPending(true);
    fetch(endpoints.updateMissingInfo, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        name: name.current.value,
        phone: phone.current.value,
        fphone: fphone.current.value,
        mphone: mphone.current.value,
        city: city.current.value,
        school: school.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPending(false);
        if (data === "no info") setInfoRequired(true);
        else setInfoRequired(false);
      })
      .catch((err) => {
        setPending(false);
        setInfoRequired(true);
      });
  };
  return (
    <div className="container responsive-pages py-3">
      <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
        أكمل البيانات
      </h2>
      <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
        {userData && (
          <form className="m-3 row" onSubmit={handleSubmitInfo}>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                الإسم رباعي
              </span>
              <input
                ref={name}
                type="text"
                className="form-control"
                placeholder={userData.billing_first_name}
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون
                </span>
                <input
                  ref={phone}
                  type="text"
                  className="form-control"
                  placeholder={userData.billing_phone}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  إيميل
                </span>
                <input
                  ref={email}
                  type="text"
                  className="form-control"
                  placeholder={userData.billing_email}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={userData.billing_email}
                  disabledcontentEditable={false}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأب
                </span>
                <input
                  ref={fphone}
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأم
                </span>
                <input
                  ref={mphone}
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المحافظة
                </span>
                <input
                  ref={city}
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المدرسة
                </span>
                <input
                  ref={school}
                  type="text"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            {!pending ? (
              <input
                type="submit"
                className="ea-btn btn rounded-ea"
                value="إرسال البيانات"
              />
            ) : (
              <button className="ea-btn btn rounded-ea" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span role="status">إرسال البيانات...</span>
              </button>
            )}
          </form>
        )}
        {errMsg && (
          <div className="d-flex p-0 m-0 justify-content-center">
            <p className="alert alert-danger m-3 text-center">{errMsg}</p>
          </div>
        )}
        {error && <h2>يجب تسجيل الدخول اولا</h2>}
      </div>
      {isPending && <Spinner />}
    </div>
  );
};

export default InfoRequired;
