import React, { useEffect, useState } from "react";
import QuestionImg from "../../../quiz/QuestionImg";
import FileUploadProgressBar from "../../FileUploadProgressBar";
import {
  handleUpload,
  maxMinCount,
  getAnswersCount,
  handlePublishQuestion,
  handleGetBankCategories,
} from "../utils";
import { useSelector } from "react-redux";

const BankQuestion = ({ teacherBankCats }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [fileProgress, setFileProgress] = useState(null);
  const [questionData, setQuestionData] = useState({
    title: null,
    imgSrc: null,
    qText: null,
    qAnsCount: null,
    qAns: null,
    qWeight: null,
    qCat: null,
  });

  const { userProfileData } = useSelector((state) => state.store);

  return (
    <form
      className="p-3 mb-3"
      onSubmit={async (e) => {
        e.preventDefault();

        if (!questionData.imgSrc && !questionData.qText)
          return alert("يرجى تحديد صورة أو محتوي للسؤال");
        if (!questionData.qCat) return alert("يرجى تحديد القسم");
        if (!questionData.qWeight) return alert("يرجى تحديد الدرجة");
        if (!questionData.qAns) return alert("يرجى تحديد الإجابة");
        if (!questionData.qAnsCount) return alert("يرجى تحديد عدد الإجابات");

        await handlePublishQuestion(setIsLoading, questionData, setSuccessMsg);
        setQuestionData({
          title: "",
          imgSrc: null,
          qText: "",
          qAnsCount: 2,
          qAns: "",
          qWeight: "",
          qCat: "",
        });
      }}
    >
      <label className="form-label" htmlFor={`quiz_question_img_input`}>
        صورة السؤال (اختياري)
      </label>
      <div>
        {fileProgress && fileProgress < 100 && fileProgress >= 0 && (
          <FileUploadProgressBar fileProgress={fileProgress} />
        )}

        <input
          onChange={(e) =>
            handleUpload(e, setFileProgress, setQuestionData, questionData)
          }
          className="elite-file lesson_form_input form-control mt-2"
          type="file"
        />
        <br />
      </div>
      {questionData.imgSrc && (
        <QuestionImg
          id={`uploaded-image`}
          src={questionData.imgSrc}
          alt=""
          classStyles="m-auto mb-2 img-fluid rounded-ea"
          border="3px solid #673de6"
        />
      )}
      <div
        className="spinner-border spinner"
        role="status"
        style={{ display: "none" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <input
        value={questionData.imgSrc}
        className="lesson_form_input form-control question_counter"
        type="hidden"
        required
      />
      <div className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label">
          محتوى السؤال (اختياري)
        </label>
        <textarea
          value={questionData.qText}
          onChange={(e) =>
            setQuestionData((prev) => ({ ...prev, qText: e.target.value }))
          }
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
      </div>
      <div className="form-floating mb-3">
        <input
          id="quiz_question_ans_count"
          type="number"
          className="form-control rounded-ea"
          required
          value={questionData.qAnsCount}
          onChange={(e) => maxMinCount(e.target.value, setQuestionData)}
        />
        <label htmlFor={`quiz_question_ans_count`}>عدد الإجابات</label>
      </div>
      <select
        className="form-select mb-3 rounded-ea"
        required
        value={questionData.qAns}
        onChange={(e) =>
          setQuestionData((prev) => ({ ...prev, qAns: e.target.value }))
        }
      >
        <option value="">الإجابة الصحيحة</option>
        {getAnswersCount(questionData).map((ans) => (
          <option value={ans}>{ans}</option>
        ))}
      </select>
      <div className="form-floating mb-3">
        <input
          value={questionData.qWeight}
          onChange={(e) =>
            setQuestionData((prev) => ({ ...prev, qWeight: e.target.value }))
          }
          id="quiz_question_weight"
          type="number"
          className="form-control rounded-ea"
          required
        />
        <label htmlFor={`quiz_question_weight`}>الدرجة</label>
      </div>
      <select
        className="form-select mb-3 rounded-ea"
        required
        value={questionData.qCat}
        onChange={(e) =>
          setQuestionData((prev) => ({ ...prev, qCat: e.target.value }))
        }
      >
        <option value="">إختر القسم الخاص بالسؤال</option>
        {teacherBankCats.map((ans) => (
          <option value={ans}>
            {ans.indexOf(` - ${userProfileData.teacher_prefix}`) > -1
              ? ans.replace(` - ${userProfileData.teacher_prefix}`, '')
              : ans}
          </option>
        ))}
      </select>
      {!isLoading && (
        <input
          type="submit"
          disabled={
            fileProgress && fileProgress < 100 && fileProgress >= 0
              ? true
              : false
          }
          id="publish_new_quiz_submit"
          className="ea-btn btn w-100 rounded-ea"
          value="إضافة للبنك"
        />
      )}
      {isLoading && (
        <button className="ea-btn btn w-100 rounded-ea" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          جاري الإضافة للبنك...
        </button>
      )}
      {successMsg && (
        <div className="alert alert-success h-50 mt-2" role="alert">
          تم إضافة السؤال لبنك الأسئلة بنجاح
        </div>
      )}
    </form>
  );
};

export default BankQuestion;
