import "../../styles/form.css";
import "../../styles/homeCustom.css";
import { useEffect } from "react";
// import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import GeneralTeacherTemp from "./teachersTemps/GeneralTeacherTemp";
import { baseUrl } from "../../hooks/BaseUrl";
import { userData } from "../../store/slices/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";

const Home = () => {
  const navigate = useNavigate();
  const [teacherHomepage, setTeacherHomepage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const { isAuthenticated, isPending } = useAuth();
  const { user, portal } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  useEffect(() => {
    if (portal) {
      setTeacherHomepage(<GeneralTeacherTemp />);
      setIsLoading(false);
    }
    // fetch(`${endpoints.getPortal}?portal=${window.location.hostname}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setTeacherHomepage(<GeneralTeacherTemp bgImg={data} />);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     localStorage.removeItem(`${window.location.hostname}_user`);
    //     dispatch(userData(null));
    //     navigate("/");
    //   });

    /*EliteTransition()*/
  }, [portal]);

  return (
    <Animation>
      <div className="home">
        {isLoading && (
          <div
            className="d-flex justify-content-center pt-5 m-auto"
            style={{ height: "20vh" }}
          >
            <div
              className="spinner-border text-white"
              style={{ fontWeight: "bold", width: "5rem", height: "5rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!user && teacherHomepage}
        {user && navigate("/school-grades")}
      </div>
    </Animation>
  );
};

export default Home;
