import { useEffect, useState } from "react";
import { baseUrl } from "../../hooks/BaseUrl";
import useFetch from "../../hooks/useFetch";
import { NavLink as Link } from "react-router-dom";
import { useRef } from "react";
import FileUploadProgressBar from "./FileUploadProgressBar";
import createPreview from "../../scripts/createPreview";
import Separator from "../Separator";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";
import { getFileUrl, getToken, unicodeToText } from "../../hooks/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  invokePortalRefetch,
  invokeUserRefetch,
} from "../../store/slices/storeSlice";
import AttachAttendanceReport from "./AttachAttendanceReport/AttachAttendanceReport";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../App";

export const payment_methods = {
  fawry: "فوري",
  vodafone_cash: "فودافون كاش",
  wallet: "محفظة",
  code: "كود",
};

const CreateLesson = () => {
  const [videosCount, setVideosCount] = useState([1]);
  const [filesCount, setFilesCount] = useState([1]);
  const [fileProgress, setFileProgress] = useState(null);
  // const [lessonFiles, setLessonFiles] = useState({});
  const [lessonFiles, setLessonFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [lessonType, setLessonType] = useState("yes");
  const [preType, setPreType] = useState(null);
  const lessonCat = useRef();
  const dispatch = useDispatch();

  // const [createLessonData, setCreateLessonData] = useState(null);
  // const [isPending, setIsPending] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   fetch(endpoints.teacherCatsQzsHwsLsns + `?timestamp=${Date()}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: getToken(),
  //     },
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw Error("Could not fetch the data");
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       setCreateLessonData(data);
  //       setIsPending(null);
  //       setError(null);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.name === "AbortError") {
  //         console.log("Fetch is aborted");
  //       } else {
  //         setError("Could not fetch the data");
  //         setIsPending(null);
  //       }
  //     });
  // }, []);

  const { userProfileData, portal } = useSelector((state) => state.store);

  const createLessonData = userProfileData?.hws_quizzes_lessons ?? null;

  console.log("cats data", createLessonData);

  const handleFileUploadFb = (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    const imageRef = ref(storage, `files-${new Date().getTime()}`);
    const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileProgress(progress.toFixed(2));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        alert("حدث خطأ");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setLessonFiles((prev) => [...prev, getFileUrl(downloadURL)]);
          setFileProgress(null);
        });
      }
    );
  };

  const handleFileUpload = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0], e.target.files[0].name);

      const xhr = new XMLHttpRequest();

      // Track the progress of the upload
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(
            "uploadProgress",
            `Upload Progress: ${percentComplete.toFixed(2)}%`
          );
          if (percentComplete.toFixed(2) === "100.00") {
            setFileProgress(null);
          } else {
            setFileProgress(percentComplete.toFixed(2));
          }
          // You can update a progress bar or display the percentage as needed
        }
      });

      // xhr.open("POST", endpoints.aws, true);
      // xhr.open("POST", endpoints.imageUpload, true);
      xhr.open("POST", endpoints.aws, true);

      // Add the Authorization header with the token
      const token = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      ).token;
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            try {
              const data = JSON.parse(xhr.responseText);

              if (data.success) {
                // File uploaded successfully
                // const tempObject = {};
                // tempObject[`lesson_file_input_${e.target.dataset.id}`] =
                //   data.url;
                // setLessonFiles({ ...lessonFiles, ...tempObject });
                setLessonFiles((prev) => [...prev, data.url]);
                console.log(data);
                setFileProgress(null);
              } else {
                // File upload failed
                alert("File upload failed. " + data.message);
                setFileProgress(null);
              }
            } catch (error) {
              alert("حدث خطأ");
              setFileProgress(null);
            }
          } else {
            // Server error
            alert("حدث خطأ");
            setFileProgress(null);
          }
        }
      };

      xhr.send(formData);
    } catch (error) {
      // Network error
      alert("An error occurred while uploading the file.");
    }
  };

  const handleSubmitLesson = async (e) => {
    e.preventDefault();
    setPublishing(true);

    try {
      const formData = new FormData(e.target);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      // formDataObject["lessonFiles"] = JSON.stringify(lessonFiles);
      formDataObject["lessonFiles"] = lessonFiles;
      if (formDataObject["video_on_off"] === "yes") {
        formDataObject["video_host"] = formDataObject["vid_url_1"].includes(
          "youtu"
        )
          ? "youtube"
          : formDataObject["vid_url_1"].includes("vimeo")
          ? "vimeo"
          : "youtube";
        const vids_data = [];
        videosCount.map((vid) => {
          const vid_id = formDataObject[`vid_url_${vid}`].includes(
            "https://youtu.be/"
          )
            ? formDataObject[`vid_url_${vid}`].replace("https://youtu.be/", "")
            : formDataObject[`vid_url_${vid}`].includes("youtube.com/watch")
            ? formDataObject[`vid_url_${vid}`].replace(
                "https://www.youtube.com/watch?v=",
                ""
              )
            : formDataObject[`vid_url_${vid}`].includes(
                "iframe.mediadelivery.net"
              )
            ? formDataObject[`vid_url_${vid}`]
            : "";
          vids_data.push({
            video_title: formDataObject[`vid_quiz_title_${vid}`],
            video_id: vid_id.includes("?si=")
              ? vid_id.split("?si=")[0].replaceAll(" ", "")
              : vid_id.replaceAll(" ", ""),
          });
        });
        formDataObject["videos_urls_titles"] = JSON.stringify(vids_data);
      }

      console.log("formDataObject", formDataObject);

      const response = await fetch(endpoints.createLesson, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(formDataObject),
      });

      if (!response.ok) {
        // Handle server errors or other issues
        console.error("Error submitting the form");
        return;
      } else {
        const successInfo = await response.json();
        setPublishing(false);
        setSuccessMsg(successInfo);
        console.log("createLesson", response);
        dispatch(invokePortalRefetch());
        dispatch(invokeUserRefetch());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Animation>
      <div className="create-lesson">
        <form
          onSubmit={(e) => handleSubmitLesson(e)}
          className="m-3 p-3 bg-white rounded-ea shadow"
        >
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            نشر حصة جديدة
          </h3>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control rounded-ea"
              id="lesson_title"
              name="lesson_title"
              placeholder="lesson_title"
              required
            />
            <label htmlFor="lesson_title">إسم الحصة</label>
          </div>
          <select
            name="video_on_off"
            onChange={(e) => setLessonType(e.target.value)}
            value={lessonType}
            className="form-select mb-3 rounded-ea"
            aria-label="نوع الحصة"
          >
            <option selected>نوع الحصة</option>
            <option value="yes">فيديو</option>
            <option value="no">إمتحان</option>
          </select>
          {lessonType === "yes" &&
            videosCount?.map((question) => (
              <>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control rounded-ea"
                    id={`vid_quiz_title_${question}`}
                    name={`vid_quiz_title_${question}`}
                    placeholder={`vid_quiz_title_${question}`}
                    required
                  />
                  <label htmlFor={`vid_quiz_title_${question}`}>
                    عنوان الفيديو أو الإمتحان
                  </label>
                </div>
                <div id={`vid_url_${question}_preview_div`}>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control rounded-ea"
                      id={`vid_url_${question}`}
                      name={`vid_url_${question}`}
                      placeholder={`vid_url_${question}`}
                      onChange={(e) => createPreview(e)}
                      required
                    />
                    <label htmlFor={`vid_url_${question}`}>لينك الفيديو</label>
                  </div>
                </div>
              </>
            ))}
          {videosCount.length > 1 && lessonType === "yes" && (
            <div id="ls_remove_vid_url" style={{ marginBottom: "10px" }}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setVideosCount((prev) => [
                    ...prev?.filter((val) => val !== prev[prev.length - 1]),
                  ]);
                }}
                id="ls_remove_vid_url_btn"
                className="btn ea-btn rounded-ea"
                style={{
                  minWidth: "13%",
                  borderRadius: "7px",
                  fontWeight: "bold",
                }}
              >
                - مسح فيديو
              </button>
            </div>
          )}
          {lessonType === "yes" && (
            <div id="ls_add_vid_url" style={{ margin: "10px 0" }}>
              <button
                onClick={() =>
                  setVideosCount((prev) => [...prev, prev.length + 1])
                }
                id="ls_add_vid_url_btn"
                className="btn ea-btn rounded-ea"
                style={{
                  minWidth: "13%",
                  borderRadius: "7px",
                  fontWeight: "bold",
                }}
              >
                + إضافة فيديو
              </button>
            </div>
          )}
          <div className="form-floating mb-3" style={{ display: "none" }}>
            <input
              type="text"
              className="form-control rounded-ea"
              id="comments_or_hw"
              name="comments_or_hw"
              placeholder="comments_or_hw"
            />
            <label htmlFor="comments_or_hw">تعليقات أو الواجب</label>
          </div>
          <Separator />
          {filesCount?.map((question, index) => (
            <>
              {fileProgress &&
                fileProgress < 100 &&
                fileProgress >= 0 &&
                index + 1 === filesCount.length && (
                  <FileUploadProgressBar fileProgress={fileProgress} />
                )}
              {/* <label
                  className="form-label"
                  htmlFor={`lesson_file_input_${question}`}
                >
                  رفع ملف
                </label> */}
              <div className="border-ea bg-ea-base-2 rounded-ea my-2 pt-3">
                {lessonFiles[index] ? (
                  <div style={{ textAlign: "center" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-file-earmark-text-fill text-ea-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
                    </svg>
                    <span>ملف رقم {index + 1}</span>
                  </div>
                ) : (
                  <input
                    style={{ textAlign: "center" }}
                    id={`lesson_file_input_${question}`}
                    onChange={(e) => handleFileUploadFb(e)}
                    data-id={question}
                    className="elite-file lesson_form_input form-control mt-2"
                    type="file"
                    placeholder="hey"
                    name={`lesson_file_input_${question}`}
                  />
                )}
                <br />
              </div>
            </>
          ))}
          {filesCount.length > 0 && (
            <div id="ls_remove_file_input" style={{ marginBottom: "10px" }}>
              <button
                disabled={
                  fileProgress && fileProgress < 100 && fileProgress >= 0
                    ? true
                    : false
                }
                onClick={(e) => {
                  e.preventDefault();
                  setFilesCount((prev) => [
                    ...prev?.filter((val) => val !== prev[prev.length - 1]),
                  ]);
                  if (lessonFiles.length === filesCount.length)
                    setLessonFiles(lessonFiles.slice(0, -1));
                }}
                id="ls_remove_file_input_btn"
                className="btn ea-btn rounded-ea"
                style={{
                  minWidth: "15%",
                  borderRadius: "7px",
                  fontWeight: "bold",
                }}
              >
                - مسح ملف
              </button>
            </div>
          )}
          <div id="ls_add_file_input" style={{ marginBottom: "10px" }}>
            <button
              disabled={
                (fileProgress && fileProgress < 100 && fileProgress >= 0) ||
                lessonFiles.length !== filesCount.length
                  ? true
                  : false
              }
              onClick={(e) => {
                e.preventDefault();
                setFilesCount((prev) => [...prev, prev.length + 1]);
              }}
              id="ls_add_file_input_btn"
              className="btn ea-btn rounded-ea"
              style={{
                minWidth: "15%",
                borderRadius: "7px",
                fontWeight: "bold",
              }}
            >
              + إضافة ملف
            </button>
          </div>
          <Separator />
          <div className="form-check form-switch my-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="xvast_protection"
              name="xvast_protection"
            />
            <label className="form-check-label ms-1" htmlFor="xvast_protection">
              تفعيل الحماية (الطالب لازم ينزل البرنامج عشان يشوف الحصة)
            </label>
          </div>
          <div className="form-check form-switch my-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="quiz_required"
              name="quiz_required"
            />
            <label className="form-check-label ms-1" htmlFor="quiz_required">
              يجب دخول الكويز قبل مشاهدة الحصة
            </label>
          </div>
          <Separator />
          <p className="fw-bold my-2">طرق الدفع</p>
          {userProfileData?.allowed_payment_methods?.map((method) => (
            <div className="form-check form-switch my-3">
              <input
                className="form-check-input"
                type="checkbox"
                id={`payment_method_${method}`}
                name={`payment_method_${method}`}
              />
              <label
                className="form-check-label ms-1"
                htmlFor={`payment_method_${method}`}
              >
                {payment_methods[method]}
              </label>
            </div>
          ))}
          {/* {userProfileData?.allowed_payment_methods?.includes("wallet") && (
            <div className="form-check form-switch my-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="payment_method_wallet"
                name="payment_method_wallet"
              />
              <label
                className="form-check-label ms-1"
                htmlFor="payment_method_wallet"
              >
                رصيد المحفظة
              </label>
            </div>
          )}
          {userProfileData?.allowed_payment_methods?.includes("fawry") && (
            <div className="form-check form-switch my-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="payment_method_fawry"
                name="payment_method_fawry"
              />
              <label
                className="form-check-label ms-1"
                htmlFor="payment_method_fawry"
              >
                فوري
              </label>
            </div>
          )}
          {userProfileData?.allowed_payment_methods?.includes(
            "vodafone_cash"
          ) && (
            <div className="form-check form-switch my-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="payment_method_vodafone_cash"
                name="payment_method_vodafone_cash"
              />
              <label
                className="form-check-label ms-1"
                htmlFor="payment_method_vodafone_cash"
              >
                فودافون كاش
              </label>
            </div>
          )} */}
          <Separator />
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control rounded-ea"
              id="price"
              name="price"
              placeholder="price"
              required
            />
            <label htmlFor="price">السعر (لو مجانية اكتب 0)</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control rounded-ea"
              id="allowed_views"
              name="allowed_views"
              placeholder="allowed_views"
              required
            />
            <label htmlFor="allowed_views">
              عدد المشاهدات المتاحة لكل طالب (لو مفتوح اكتب 0)
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control rounded-ea"
              id="allowed_time"
              name="allowed_time"
              placeholder="allowed_time"
              required
            />
            <label htmlFor="allowed_time">
              عدد أيام صلاحية الحصة بعد الشراء (لو مفتوح اكتب 0)
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="datetime-local"
              className="form-control rounded-ea"
              id="last_purchase_date"
              name="last_purchase_date"
              placeholder="last_purchase_date"
            />
            <label htmlFor="last_purchase_date">
              اخر ميعاد لشراء الحصة (إختياري)
            </label>
          </div>
          <Separator />
          <select
            ref={lessonCat}
            name="prod_cat"
            className="form-select mb-3 rounded-ea"
            aria-label="المرحلة أو القسم"
          >
            <option disabled selected>
              المرحلة أو القسم
            </option>
            {portal?.cats
              .filter((item) => item.parent !== 0)
              ?.map((cat) => (
                <option value={cat.slug}>{cat.name}</option>
              ))}
          </select>
          <select
            name="quiz_id"
            className="form-select mb-3 rounded-ea"
            aria-label="الكويز"
          >
            <option selected value="">
              الكويز
            </option>
            {userProfileData?.hws_quizzes_lessons
              ?.filter((item) => item.post_type === "quiz")
              ?.map((quiz) => (
                <option value={quiz.ID}>{quiz.post_title}</option>
              ))}
          </select>
          <select
            name="hw_id"
            className="form-select mb-3 rounded-ea"
            aria-label="الواجب"
          >
            <option selected value="">
              الواجب
            </option>
            {userProfileData?.hws_quizzes_lessons
              ?.filter((item) => item.post_type === "homework")
              ?.map((homework) => (
                <option value={homework.ID}>{homework.post_title}</option>
              ))}
          </select>
          <Separator />
          <p className="my-2">
            يجب على الطالب حل واجب أو كويز حصة معينة قبل شراء هذه الحصة
          </p>
          <select
            onChange={(e) => setPreType(e.target.value)}
            name="prereq_type"
            className="form-select mb-3 rounded-ea"
            aria-label="يجب على الطالب حل واجب أو كويز حصة معينة قبل شراء هذه الحصة"
          >
            <option selected>إختر</option>
            <option value="quiz">نعم يجب حل الكويز</option>
            <option value="hw">نعم يجب حل الواجب</option>
          </select>
          {preType && (
            <select
              name="prereq"
              className="form-select mb-3 rounded-ea"
              aria-label="choose-prereq"
            >
              <option disabled selected>
                إختر الحصة
              </option>
              {userProfileData?.hws_quizzes_lessons
                ?.filter((item) => item.post_type === "product")
                ?.map((lesson) => (
                  <option value={lesson.ID}>{lesson.post_title}</option>
                ))}
            </select>
          )}
          <Separator />
          <AttachAttendanceReport />
          {!publishing && (
            <input
              type="submit"
              disabled={
                fileProgress && fileProgress < 100 && fileProgress >= 0
                  ? true
                  : false
              }
              id="publish_new_quiz_submit"
              className="ea-btn btn w-100 rounded-ea"
              value="نشر"
            />
          )}
          {publishing && (
            <button
              className="ea-btn btn w-100 rounded-ea"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              جاري نشر الحصة...
            </button>
          )}
          {successMsg && (
            <div className="alert alert-success h-50 mt-2" role="alert">
              تم النشر{" "}
              <Link
                to={`/category/${successMsg.teacher_slug}/${successMsg.lesson_slug}`}
              >
                إضغط هنا للذهاب للحصة
              </Link>
            </div>
          )}
        </form>
      </div>
    </Animation>
  );
};

export default CreateLesson;
