import React, { useState } from "react";
import { useSelector } from "react-redux";
import { unicodeToText } from "../../../hooks/utils";

const AttachAttendanceReport = () => {
  const [attachAttendanceReport, setAttachAttendanceReport] = useState(false);
  const { userProfileData } = useSelector((state) => state.store);
  const pendingReports =
    userProfileData?.pending_attendance_reports?.length > 0
      ? userProfileData?.pending_attendance_reports
      : null;

  const getReportTitle = (report) => {
    const decoded = JSON.parse(report);
    console.log(
      "caewfqwfwefw",
      `${unicodeToText(
        decoded?.offline_group.split("-")
      )} - اسبوع رقم ${decoded?.week.slice(1)}`
    );
    return `${unicodeToText(
      decoded?.offline_group.split("-")
    )} - اسبوع رقم ${decoded?.week.slice(1)}`;
  };

  return (
    <>
      <div className="form-check form-switch my-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="attach_attendance_report_check"
          name="attach_attendance_report_check"
          onChange={() => setAttachAttendanceReport((prev) => !prev)}
        />
        <label
          className="form-check-label ms-1"
          htmlFor="attach_attendance_report_check"
        >
          إرفاق تقرير حضور لهذه الحصة
        </label>
      </div>
      {attachAttendanceReport && (
        <select
          name="attendance_report"
          className="form-select mb-3 rounded-ea"
          aria-label="attendance_report"
        >
          <option disabled selected>
            إختر التقرير
          </option>
          {pendingReports?.map((report) => (
            <option value={report}>
              {getReportTitle(report)}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default AttachAttendanceReport;
