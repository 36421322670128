import { useEffect, useState } from "react";
import { NavLink as Link, useParams } from "react-router-dom";
// import { gsap } from "gsap";
// import useAuth from "../../hooks/useAuth";
import { baseUrl } from "../../hooks/BaseUrl";
import Spinner from "../Spinner";
import { useSelector, useDispatch } from "react-redux";
import { teachersData } from "../../store/slices/storeSlice";
import Animation from "../Animation";
import { endpoints } from "../../hooks/endpoints";
import { getFileUrl } from "../../hooks/utils";

const Groups = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { currentTeacher, portal } = useSelector((state) => state.store);

  const { schoolgrade } = useParams();

  const getGroupsFromPortal = () => {
    if (!portal) return [];

    const grade = portal.cats.filter(
      (item) => decodeURIComponent(item.slug) === schoolgrade
    )[0];
    return portal.cats.filter((item) => item.parent === grade?.term_id);
  };

  const dispatch = useDispatch();

  // const animation = () => {
  //   gsap.from(".card", {
  //     duration: 1.5,
  //     y: 150,
  //     opacity: 0,
  //     ease: "power2.out",
  //     stagger: 0.2,
  //     onComplete: () => {
  //       gsap.set(".card", { clearProps: "all" });
  //     },
  //   });
  // };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, [currentTeacher]);

  // const { teachersData, dispatch } = useTeachersContext()

  // const [teachers, setTeachers] = useState([]);
  // const [isPending, setIsPending] = useState(true);
  // const [error, setError] = useState(false);
  // useAuth();

  // useEffect(() => {
  //   fetch(endpoints.teachersInfo + "?refresh=1")
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setTeachers(result);
  //       setIsPending(false);
  //       setError(false);
  //       console.log("teachers", result);
  //       // dispatch({type: 'SET_TEACHERS', payload: teachersData})
  //       dispatch(teachersData(result));
  //       // animation();
  //       const cards = document.querySelectorAll(".card");
  //       const row = document.querySelector(".row");
  //       row.classList.add("bg-white", "shadow");
  //       setTimeout(() => {
  //         cards.forEach((card) => {
  //           card.classList.add("grow");
  //         });
  //       }, 1500);
  //     });
  // }, [teachersData, currentTeacher]);

  return (
    <Animation>
      <div className="teachers">
        <div
          className="bg-purple-svg"
          style={{
            padding: "1rem 0 10rem 0",
            textAlign: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <p className="h1 text-ea-primary fw-bold">المجموعة</p>
        </div>

        {!portal && <Spinner />}
        {portal?.data?.length > 0 && (
          <Animation>
            <div>
              <div
                className="row responsive-padding-card rounded-ea"
                style={{
                  width: "80%",
                  margin: "5px auto",
                  transform: "translateY(-200px)",
                }}
              >
                {portal &&
                  // !error &&
                  getGroupsFromPortal().map((item) => (
                    <div
                      className="col-sm-6 col-md-4 col-lg-3 mb-4"
                      key={item.term_taxonomy_id}
                    >
                      <div
                        className="card shadow w-100 rounded-ea bg-ea border-0"
                        style={{ width: "18rem" }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          to={{
                            pathname: `/${schoolgrade}/${item.slug}/lessons`,
                            state: { teacherData: item },
                          }}
                        >
                          <div className="rounded-ea">
                            <img
                              src={decodeURIComponent(portal.cardImg)}
                              className="card-img-top"
                              alt={item.term_name?.split("|")[1] ?? portal.name}
                              style={{
                                borderRadius: "15px",
                              }}
                            />
                            <div>
                              <p
                                className="fs-6 text-white fw-bold"
                                style={{
                                  position: "absolute",
                                  bottom: isMobile ? "1rem" : ".3rem",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.name?.indexOf("|") > -1
                                  ? item.name?.split("|")[0]
                                  : item.name}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Animation>
        )}
      </div>
    </Animation>
  );
};

export default Groups;
