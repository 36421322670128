/**
 * This page is cloned from the invoices page so it might
 * have similar naming conventions to the invoices page
 *
 */

import { NavLink as Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../hooks/BaseUrl.js";
import Animation from "../Animation.jsx";
import { endpoints } from "../../hooks/endpoints.js";
import { useSelector } from "react-redux";
import { getToken } from "../../hooks/utils.js";

const QuizResults = () => {
  const [invoicesStart, setInvoicesStart] = useState(0);
  const [allInvoices, setAllInvoices] = useState();
  const [invoices, setInvoices] = useState();
  // const [cats, setCats] = useState();
  const [currentCat, setCurrentCat] = useState();
  const [groups, setGroups] = useState();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  // const [teacherData, setTeacherData] = useState({ name: "", subject: "" });
  const lessonRef = useRef();
  const gradeTypeRef = useRef();

  const { userProfileData, portal } = useSelector((state) => state.store);
  const teacherData = {
    name: userProfileData.teacher_name,
    subject: userProfileData.teacher_subject,
  };

  const cats = userProfileData.categories;
  const lessons = portal.data;

  const formatPhoneNumber = (phoneNumberString) => {
    return phoneNumberString.replaceAll(" ", "").split("-");
  };
  const getLessonTitle = () => {
    return groups
      .filter((group) => group.cat === currentCat)[0]
      .lessons.filter(
        (lesson) => lesson.ID.toString() === lessonRef.current.value.toString()
      )[0].post_title;
  };
  const getStudentScore = async (invoice, phone) => {
    const data = await JSON.parse(invoice.raw_data);
    window.open(
      `https://api.whatsapp.com/send?phone=2${phone}&text=السادة%20أولياء%20أمر%20الطالب/ة%20${
        invoice.student_name
      }%20,%20نتيجة%20الاختبار%20للحصة%20${
        "*" + getLessonTitle() + "*"
      }%20هي%20${data.score + " / " + data.maxScore}%20,%20${
        teacherData.name
      }%20مادة%20${teacherData.subject}`,
      "_blank"
    );
  };

  // const getTeacherNameAndSubject = () => {
  //   fetch(endpoints.teacehrDataQuizReport + `?timestamp=${Date()}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem(`${window.location.hostname}_user`)
  //         ? "Bearer" +
  //           JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
  //             .token
  //         : null,
  //     },
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setTeacherData(data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const getGrades = (e) => {
    e.preventDefault();
    if (!lessonRef.current.value || gradeTypeRef.current.value === "-") return;
    setIsPending(true);
    fetch(
      endpoints.qzHwResultTeacehr +
        `?lesson=${lessonRef.current.value}&gradetype=${gradeTypeRef.current.value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsPending(false);
        console.log(data);
        setAllInvoices(data);
        setInvoices(data);
        if (
          data.filter((invoice) =>
            invoice.title
              .replace(/&#8211;/g, "-")
              .includes(
                lessons.filter(
                  (v) => v.ID.toString() === lessonRef.current.value
                )[0]?.post_title
              )
          ).length === 0
        ) {
          setError("لا يوجد نتائج لهذا الإختبار");
        } else {
          setError(null);
        }
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   getTeacherNameAndSubject();
  // }, []);

  // useEffect(() => {
  //   if (!invoices) {
  //     fetch(endpoints.getTeacherCatLessons + `?timestamp=${Date()}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem(
  //           `${window.location.hostname}_user`
  //         )
  //           ? "Bearer" +
  //             JSON.parse(
  //               localStorage.getItem(`${window.location.hostname}_user`)
  //             ).token
  //           : null,
  //       },
  //       method: "GET",
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const outputArray = [];
  //         for (const orderId in data.orders) {
  //           if (data.orders.hasOwnProperty(orderId)) {
  //             const order = data.orders[orderId];
  //             order["order_id"] = orderId;
  //             outputArray.push(order);
  //           }
  //         }
  //         const rev_arr = outputArray.reverse();
  //         setAllInvoices(rev_arr);
  //         setInvoices(rev_arr);
  //         setGroups(data.cat_lessons);
  //         setCurrentCat(data.cats);
  //         setCats(data.cats);
  //         setIsPending(false);
  //       })
  //       .catch((error) => {
  //         setError(error);
  //         setIsPending(false);
  //       });
  //   }
  // }, [invoicesStart]);

  // useEffect(() => {
  //   if(!lessonRef || !groups || !currentCat) return;
  //   console.log(
  //     "cwefsfs",
  //     groups
  //       .filter((v) => v.cat === currentCat)[0]
  //       .lessons.filter((v) => v.ID.toString() === lessonRef.current.value)[0]
  //       .post_title
  //   );
  // },[lessonRef])

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            عرض النتائج
          </h3>
          <form onSubmit={(e) => getGrades(e)}>
            <select
              onChange={(e) => {
                lessonRef.current.value = null;
                gradeTypeRef.current.value = "-";
                setCurrentCat(e.target.value);
              }}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="-" selected>
                القسم
              </option>
              {cats && cats.map((cat) => <option value={cat}>{cat}</option>)}
            </select>
            <select
              ref={lessonRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="all" selected>
                الحصة
              </option>
              {lessons &&
                lessons
                  .filter((lesson) => lesson.slug === currentCat)
                  .map((lesson) => (
                    <option value={lesson.ID}>{lesson.post_title}</option>
                  ))}
            </select>
            <select
              ref={gradeTypeRef}
              className="form-select w-75 my-2"
              aria-label="Default select example"
            >
              <option value="-" selected>
                نوع الإمتحان
              </option>
              <option value="grades">كويز</option>
              <option value="hwgrades">واجب</option>
            </select>
            {!isPending && (
              <input
                type="submit"
                className="btn ea-btn rounded-ea my-2"
                value="عرض"
              />
            )}
            {isPending && (
              <button
                className="btn ea-btn rounded-ea my-2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                عرض...
              </button>
            )}
          </form>
          {invoices && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">الطالب</th>
                    <th scope="col">رقم الطالب</th>
                    <th scope="col">إرسال التقرير لولي الأمر</th>
                    <th scope="col">الدرجة</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    lessonRef.current.value &&
                    invoices
                      .filter((invoice) =>
                        invoice.title
                          .replace(/&#8211;/g, "-")
                          .includes(
                            lessons.filter(
                                (v) =>
                                  v.ID.toString() === lessonRef.current.value
                              )[0]?.post_title
                          )
                      )
                      .map(
                        (invoice, index) =>
                          index >= invoicesStart &&
                          index < invoicesStart + 10 && (
                            <tr key={invoice.ID}>
                              <td scope="row">{index + 1}</td>
                              <td scope="row" style={{ whiteSpace: "nowrap" }}>
                                {invoice.student_name}
                              </td>
                              <td scope="row">{invoice.student_phone}</td>
                              <td scope="row" style={{ whiteSpace: "nowrap" }}>
                                {formatPhoneNumber(invoice.parent_phone).map(
                                  (phone) => (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        getStudentScore(invoice, phone)
                                      }
                                      style={{
                                        maxHeight: "30px",
                                        padding: "3px 10px",
                                        whiteSpace: "nowrap",
                                      }}
                                      className="pt-0 btn ea-btn rounded-ea mx-1"
                                    >
                                      {phone}
                                    </button>
                                  )
                                )}
                              </td>
                              <td>{invoice.grade}</td>
                              <td>{invoice.date_submitted}</td>
                              <td>
                                <Link
                                  to={`/grades/${invoice.ID}`}
                                  style={{
                                    maxHeight: "30px",
                                    padding: "3px 8px",
                                  }}
                                  className="pt-0 btn ea-btn rounded-ea"
                                >
                                  تفاصيل
                                </Link>
                              </td>
                            </tr>
                          )
                      )}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex">
            {invoices &&
              groups &&
              invoicesStart + 10 <
                invoices.filter((invoice) =>
                  invoice.title
                    .replace(/&#8211;/g, "-")
                    .includes(
                      lessons.filter(
                          (v) => v.ID.toString() === lessonRef.current.value
                        )[0]?.post_title
                    )
                ).length && (
                <button
                  onClick={() => setInvoicesStart((prev) => prev + 10)}
                  className="m-1 btn ea-btn rounded-ea"
                >
                  التالي
                </button>
              )}
            {invoices && invoicesStart > 0 && (
              <button
                onClick={() => setInvoicesStart((prev) => prev - 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>
          {error && <h4>{error}</h4>}
        </div>
      </div>
    </Animation>
  );
};

export default QuizResults;
