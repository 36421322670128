import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Xvast from "./Xvast";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { applyWatermark } from "./applyWatermark";
import { baseUrl } from "../../hooks/BaseUrl";
import EliteVideoPlayer from "./EliteVideoPlayer";
import VimeoVideo from "./VimeoVideo";
import { endpoints } from "../../hooks/endpoints";

const VideosMap = ({ lessonData, expirtDate, files, protection }) => {
  // const options = {
  //   quality: {
  //     default: "small",
  //     options: ["small", "hd720"],
  //   },
  //   controls: {
  //     fastForward: 10,
  //     rewind: 10,
  //   },
  // };

  const [decrementView, setDecrementView] = useState(false);
  const domain = window.location.hostname;

  const handleTimeUpdate = () => {
    const userInLocalStorage = JSON.parse(
      localStorage.getItem(`${window.location.hostname}_user`)
    );

    // Check if video progress is 80% or more
    if (
      //&& progress <= 85
      JSON.parse(lessonData.videos_data).length < 2 &&
      expirtDate !== "infinite"
    ) {
      // Send fetch request to the backend API
      fetch(endpoints.videoView, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInLocalStorage.token}`,
        },
        body: JSON.stringify({
          lesson_id: lessonData.lesson_id,
        }),
        redirect: "follow",
      })
        .then((response) => {
          // Handle the response
          console.log(response);
          console.log(
            "video view",
            `80% of video reached for lesson id ${lessonData.lesson_id}`
          );
        })
        .catch((error) => {
          // Handle the error
          console.log(error);
        });
    }
  };
  const [isProtected, setIsProtected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const playerRef = useRef(null);

  const isBunny = (url) => {
    return url.indexOf("play/") !== -1 ? true : false;
  };

  // const testPlay = () => {
  //   const vidPlayer = playerRef.current.plyr
  //   vidPlayer.play();
  // }

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
    // console.log("testPlayerPlyr", playerRef.current.plyr)
    applyWatermark();
    const browser = window.navigator.userAgent;
    if (
      browser.indexOf("Xvast") === -1 &&
      protection &&
      (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      ) ||
        domain === "mohamedyehyafr.com")
    ) {
      setIsProtected(true);
    }

    if (decrementView) {
      handleTimeUpdate();
    }

    console.log("playerRefIframe", document.querySelector("iframe"));

    if (playerRef.current && !decrementView) {
      setTimeout(() => {
        playerRef.current
          ? playerRef.current.plyr.on("timeupdate", (event) => {
              const duration = event.detail.plyr.duration;
              const currentTime = event.detail.plyr.currentTime;
              const progress = (currentTime / duration) * 100;
              if (progress > 80) {
                setDecrementView(true);
              }
            })
          : setDecrementView(false);
      }, 10000);
    }
  }, [decrementView, playerRef.current]);

  const location = window.location.hostname;

  return (
    <>
      <div className="pt-2 pe-4 ps-4 pb-4">
        {(!isProtected || location === "localhost") && lessonData ? (
          JSON.parse(lessonData.videos_data).map((video, index) => (
            <div key={video.video_id}>
              <div
                className="w-100 my-2 p-2 bg-ea-base-2 border-ea rounded-ea text-ea-primary"
                style={{ textAlign: "center" }}
                role="alert"
              >
                <h5 className="d-inline">{video && video.video_title}</h5>
              </div>
              <div className="d-flex">
                {/* {lessonData && lessonData.video_host === "vimeo" && (
                  <VimeoVideo
                    expirtDate={expirtDate}
                    videoId={video.video_id}
                    lesson_id={lessonData.lesson_id}
                  />
                )} */}
                {/* <EliteVideoPlayer
                  className="w-100"
                  vidId={video.video_id}
                  expirtDate={expirtDate}
                  lessonData={lessonData}
                /> */}
                {lessonData && lessonData.video_host === "vimeo" ? (
                  <VimeoVideo videoUrl={video.video_id} />
                ) : isBunny(video.video_id) ? (
                  <iframe
                    src={`https://iframe.mediadelivery.net/embed/${
                      video.video_id.split("play/")[1]
                    }?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
                    loading="lazy"
                    style={{
                      border: 0,
                      top: 0,
                      minHeight: "500px",
                      height: "100%",
                      width: "100%",
                      pointerEvents: "auto",
                    }}
                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                    allowFullScreen={true}
                  />
                ) : isMobile ? (
                  <Plyr
                    source={{
                      type: "video",
                      sources: [
                        {
                          src: video.video_id,
                          provider: "youtube",
                        },
                      ],
                    }}
                    ref={playerRef}
                  />
                ) : (
                  <EliteVideoPlayer
                    className="w-100"
                    vidId={video.video_id}
                    expirtDate={expirtDate}
                    lessonData={lessonData}
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <Xvast />
        )}
        <div className="m-3" style={{ textAlign: "center" }}>
          {files &&
            files.length > 0 &&
            files[0] !== "{}" &&
            files.map((file, index) => (
              <a
                className="btn ea-btn rounded-ea my-2 mx-2 lesson-file-btn"
                style={{ width: "100%" }}
                href={file}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="currentColor"
                  className="bi bi-file-earmark-text-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
                </svg>{" "}
                <span>ملف رقم {index + 1}</span>
              </a>
            ))}
        </div>
      </div>
    </>
  );
};

export default VideosMap;
