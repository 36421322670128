import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LessonsMap from "../lesson/LessonsMap";
import Spinner from "../Spinner";
import { baseUrl } from "../../hooks/BaseUrl";
import Animation from "../Animation";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../hooks/endpoints";
import { getFileUrl } from "../../hooks/utils";

const LessonsList = () => {
  const { group } = useParams();

  const [isMobile, setIsMobile] = useState(false);
  const { portal } = useSelector((state) => state.store);
  const lessons = portal?.data?.filter((item) => item.slug === group);
  // const [data, setData] = useState(null);
  // const [error, setError] = useState(null);

  // const urldecode = (str) => {
  //   return decodeURIComponent(str?.replace(/\+/g, " "));
  // };

  // useEffect(() => {
  //   fetch(
  //     endpoints.teacherData +
  //       "?refresh=1&teacher=" +
  //       slug.replace(/[\t\n]/g, ""),
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${
  //           JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
  //             .token
  //         }`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setData(result);
  //     })
  //     .catch((err) => setError(err));
  // }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  return (
    <Animation>
      <div className="teacher bg-ea-base" style={{ minHeight: "90vh" }}>
        <div
          className="bg-ea-primary"
          style={{
            padding: "1rem 0 7rem 0",
            textAlign: "center",
            width: "100%",
          }}
        ></div>
        {portal ? (
          <Animation>
            <div className="row w-75 m-auto">
              <div
                className="col-lg-4"
                style={{
                  transform: "translateY(-70px)",
                }}
              >
                <div className="card m-auto rounded-ea shadow">
                  <div style={{ position: "relative" }}>
                    <img
                      src={decodeURIComponent(portal.cardImg)}
                      className="card-img-top rounded-ea"
                      alt={portal.name}
                    />
                    <p
                      className="fs-5 text-white fw-bold"
                      style={{
                        position: "absolute",
                        bottom: isMobile ? ".8rem" : "1.1rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                        whiteSpace: "nowrap",
                        zIndex: "9999",
                      }}
                    >
                      {lessons &&
                        (lessons[0]?.term_name?.indexOf("|") > -1
                          ? lessons[0]?.term_name?.split("|")[0]
                          : lessons[0]?.term_name)}
                    </p>
                  </div>
                  <ul className="list-group list-group-flush rounded-ea">
                    <li className="list-group-item">
                      الحصص ({lessons?.length})
                    </li>
                    <li className="list-group-item">
                      الإمتحانات ({lessons?.length})
                    </li>
                    {/* <li className="list-group-item">
                      الملازم ({data && data.teacherData.books.length})
                    </li> */}
                    {/* <li className="list-group-item">
            <span className="m-2 text-primary">
              <a href="https://google.com" target="_blank" rel="noreferrer">
                <i className="bi bi-whatsapp"></i>
              </a>
            </span>
            <span className="m-2 text-primary">
              <a href="https://google.com" target="_blank" rel="noreferrer">
                <i className="bi bi-facebook"></i>
              </a>
            </span>
          </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 bg-white shadow rounded-ea my-3 pt-2">
                <div className="h4 text-white bg-ea-primary rounded-ea py-2 px-4 d-flex mb-2">
                  الحصص
                </div>
                {!lessons ? (
                  <Spinner />
                ) : (
                  <LessonsMap
                  // teacherData={data.teacherData}
                  // lessons={data.lessons}
                  />
                )}
                {/* <ul
          className="nav nav-pills w-100 res-tabs"
          id="pills-tab"
          role="tablist"
        >
          <li
            className="nav-item m-auto"
            role="presentation"
            style={{ width: "20%" }}
          >
            <button
              className="nav-link ea-toggle rounded-ea text-dark w-100 ps-1 pe-1 pt-2 pb-2 active"
              id="pills-lessons-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-lessons"
              type="button"
              role="tab"
              aria-controls="pills-lessons"
              aria-selected="true"
            >
              حصص <i className="bi bi-collection-play"></i>
            </button>
          </li>
          <li
            className="nav-item m-auto"
            role="presentation"
            style={{ width: "20%" }}
          >
            <button
              disabled
              className="nav-link rounded-ea ea-toggle w-100 text-dark ps-1 pe-1 pt-2 pb-2"
              id="pills-exams-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-exams"
              type="button"
              role="tab"
              aria-controls="pills-exams"
              aria-selected="false"
            >
              إمتحانات <i className="bi bi-file-text"></i>
            </button>
          </li>
          <li
            className="nav-item m-auto"
            role="presentation"
            style={{ width: "20%" }}
          >
            <button
              disabled
              className="nav-link rounded-ea ea-toggle w-100 text-dark ps-1 pe-1 pt-2 pb-2"
              id="pills-revision-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-revision"
              type="button"
              role="tab"
              aria-controls="pills-revision"
              aria-selected="false"
            >
              مراجعات <i className="bi bi-pencil"></i>
            </button>
          </li>
          <li
          className="nav-item m-auto"
          role="presentation"
          style={{ width: "20%" }}
        >
          <button
            className="nav-link rounded-ea ea-toggle w-100 text-dark ps-1 pe-1 pt-2 pb-2"
            id="pills-books-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-books"
            type="button"
            role="tab"
            aria-controls="pills-books"
            aria-selected="false"
          >
            ملازم <i className="bi bi-journals"></i>
          </button>
        </li>
        <li
          className="nav-item m-auto"
          role="presentation"
          style={{ width: "20%" }}
        >
          <button
            className="nav-link rounded-ea ea-toggle w-100 text-dark ps-1 pe-1 pt-2 pb-2"
            id="pills-reservation-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-reservation"
            type="button"
            role="tab"
            aria-controls="pills-reservation"
            aria-selected="false"
          >
            حجز <i className="bi bi-calendar3"></i>
          </button>
        </li>
        </ul>
        <div className="tab-content pt-3" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-lessons"
            role="tabpanel"
            aria-labelledby="pills-lessons-tab"
          >
            {!data ? (
              <Spinner />
            ) : (
              <LessonsMap
                teacherData={data.teacherData}
                lessons={data.lessons}
              />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-exams"
            role="tabpanel"
            aria-labelledby="pills-exams-tab"
          >
            {!data ? (
              <Spinner />
            ) : (
              <ExamsMap
                teacherData={data.teacherData}
                lessons={data.lessons}
                noExams={data.lessons.filter(
                  (lesson) => lesson.quiz_id !== null
                )}
              />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-revision"
            role="tabpanel"
            aria-labelledby="pills-revision-tab"
          >
            {!data ? (
              <Spinner />
            ) : (
              <RevisionsMap
                teacherData={data.teacherData}
                lessons={data.lessons}
              />
            )}
          </div>
          <div
            className="tab-pane fade"
            id="pills-books"
            role="tabpanel"
            aria-labelledby="pills-books-tab"
          >
            {data && <BooksMap teacherInfo={data.teacherData} />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-reservation"
            role="tabpanel"
            aria-labelledby="pills-reservation-tab"
          >
            <div className="text-ea-primary bg-ea-base border-ea rounded-ea p-2 d-flex mb-2">
              <p className="h4 m-auto d-inline">حجز السنة الجديدة</p>
            </div>
            <TeacherReservation />
          </div>
        </div> */}
              </div>
            </div>
          </Animation>
        ) : (
          <Spinner />
        )}
      </div>
    </Animation>
  );
};

export default LessonsList;
