import Spinner from "../Spinner.js";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router";
import NotFound from "../NotFound.js";
import { useEffect } from "react";
import QuestionImg from "./QuestionImg.js";
import Animation from "../Animation.jsx";
import { endpoints } from "../../hooks/endpoints.js";
import { getFileUrl } from "../../hooks/utils.js";

const Grade = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    data: grade,
    isPending,
    error,
  } = useFetch(`${endpoints.getGrade}?grade=${id}`);

  if (grade && !grade.raw_data) navigate("/grades");

  useEffect(() => {
    /*EliteTransition()*/
  }, []);

  return (
    <Animation>
      <div>
        <div className="responsive-grade mx-auto">
          {grade && (
            <div className="p-3">
              <div className="d-flex">
                <img
                  className="image-fluid w-50 mx-auto"
                  src="https://drive.google.com/uc?id=11oWusZr8VM7oYq5axs4k4_WSJqcie-s2"
                  alt=""
                />
              </div>
              <div className="d-flex mb-3">
                <h3 className="m-auto">درجتك هي</h3>
              </div>
              <p className="fw-bold my-auto fs-5 m-2 text-center">
                {grade.raw_data
                  ? `${JSON.parse(grade.raw_data).score} من ${
                      JSON.parse(grade.raw_data).maxScore
                    }`
                  : ""}
              </p>
              <div
                className="progress rounded-ea responsive-quiz-grade mx-auto"
                style={{ height: "1.5rem", backgroundColor: "#ddd" }}
              >
                <div
                  className="progress-bar rounded-ea"
                  role="progressbar"
                  style={{
                    width: grade.raw_data
                      ? `${
                          (JSON.parse(grade.raw_data).score /
                            JSON.parse(grade.raw_data).maxScore) *
                          100
                        }%`
                      : "0px",
                    height: "1.5rem",
                  }}
                  aria-valuenow={
                    grade.raw_data
                      ? `${
                          (JSON.parse(grade.raw_data).score /
                            JSON.parse(grade.raw_data).maxScore) *
                          100
                        }%`
                      : "0px"
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          )}
          {grade &&
            grade.questions.map((question) => (
              <div
                key={question.id}
                className={`p-2 mt-3 bg-white mb-3 mx-4 border-ea-2 rounded-ea quiz-ended`}
              >
                <div className="rounded-ea d-flex">
                  {/* <img
                  style={{ maxHeight: "350px", maxWidth: "100%" }}
                  src={question.img}
                  className="m-auto rounded-3"
                  alt=""
                /> */}
                  <QuestionImg src={question.img} alt="" />
                </div>
                <div
                  className="my-2 btn-group w-100"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {question.answers[3] ? (
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name={question.id}
                        id={`btnradio-${question.id}-${question.answers[3]}`}
                        value={question.answers[3]}
                        autoComplete="off"
                        checked={
                          question.student_answer === question.answers[3]
                            ? true
                            : null
                        }
                        // required={lessonData.must_answer_all === 'on' ? true : false}
                      />
                      <label
                        className="btn btn-outline-primary fw-bold"
                        htmlFor={`btnradio-${question.id}-${question.answers[3]}`}
                      >
                        {question.answers[3]}
                      </label>
                    </>
                  ) : (
                    ""
                  )}

                  <input
                    type="radio"
                    className="btn-check"
                    name={question.id}
                    id={`btnradio-${question.id}-${question.answers[2]}`}
                    value={question.answers[2]}
                    autoComplete="off"
                    checked={
                      question.student_answer === question.answers[2]
                        ? true
                        : null
                    }
                    // required={lessonData.must_answer_all === 'on' ? true : false}
                  />
                  <label
                    className="btn btn-outline-primary fw-bold"
                    htmlFor={`btnradio-${question.id}-${question.answers[2]}`}
                  >
                    {question.answers[2]}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name={question.id}
                    id={`btnradio-${question.id}-${question.answers[1]}`}
                    value={question.answers[1]}
                    autoComplete="off"
                    checked={
                      question.student_answer === question.answers[1]
                        ? true
                        : null
                    }
                    // required={lessonData.must_answer_all === 'on' ? true : false}
                  />
                  <label
                    className="btn btn-outline-primary fw-bold"
                    htmlFor={`btnradio-${question.id}-${question.answers[1]}`}
                  >
                    {question.answers[1]}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name={question.id}
                    id={`btnradio-${question.id}-${question.answers[0]}`}
                    value={question.answers[0]}
                    autoComplete="off"
                    checked={
                      question.student_answer === question.answers[0]
                        ? true
                        : null
                    }
                    // required={lessonData.must_answer_all === 'on' ? true : false}
                  />
                  <label
                    className="btn btn-outline-primary fw-bold"
                    htmlFor={`btnradio-${question.id}-${question.answers[0]}`}
                  >
                    {question.answers[0]}
                  </label>
                </div>
                <div className="d-flex">
                  <div className="d-flex w-75 mx-auto">
                    <div className="mx-auto">
                      {question.correct === "true" && (
                        <div className="d-flex mx-1">
                          <div
                            style={{ maxHeight: "50px" }}
                            className="d-flex py-2 px-4 rounded-ea alert border-success alert-success mx-auto"
                            role="alert"
                          >
                            <p className="fw-bold m-auto">إجابة صحيحة</p>
                          </div>
                        </div>
                      )}
                      {question.correct !== "true" && (
                        <div className="d-flex mx-1">
                          <div
                            style={{ maxHeight: "50px" }}
                            className="d-felx py-2 px-4 rounded-ea alert border-danger alert-danger mx-auto"
                            role="alert"
                          >
                            {grade.hide_correct_answers ? (
                              <p className="fw-bold m-auto">إجابة خاطئة</p>
                            ) : (
                              <p className="fw-bold m-auto">{`الإجابة الصحيحة هي ${question.correct_answer}`}</p>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="d-flex mx-1">
                        <div
                          style={{ maxHeight: "50px" }}
                          className="d-felx py-2 px-4 rounded-ea alert alert-warning mx-auto border-warning"
                          role="alert"
                        >
                          <p className="m-auto fw-bold">
                            الدرجة: {question.weight}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error && <NotFound />}
              </div>
            ))}
          {grade && grade === "Not the student" && <NotFound />}
          {isPending && <Spinner />}
        </div>
      </div>
    </Animation>
  );
};

export default Grade;
