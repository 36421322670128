import { NavLink as Link } from "react-router-dom";
import Logout from "../../hooks/useLogout";
import { useEffect, useState } from "react";
// import { useAuthContext } from '../hooks/useAuthContext';
// import { useTeachersContext } from '../hooks/useTeachersContext';
// import useAuth from "../../hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
// import { teachersData } from '../../store/slices/storeSlice';
// import Wallet from './Wallet';
import useFetch from "../../hooks/useFetch";
import {
  teachersData,
  walletData,
  userIsTeacher,
  currentTeacherData,
  userData,
  setPortal,
  setUserProfileData,
} from "../../store/slices/storeSlice";
import { baseUrl } from "../../hooks/BaseUrl";
import { teachersList } from "../TeachersList";
import { useNavigate } from "react-router-dom";
import { getToken, unicodeToText } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";

const Navbar = () => {
  const { user, wallet } = useSelector((state) => state.store);
  const [mainPage, setMainPage] = useState("/");
  // const [isTeacher, setIsTeacher] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const domain = window.location.hostname;

  // const { data: teachers } = useFetch(endpoints.teachersInfo1);

  // const fetchWallet = () => {
  //   fetch( endpoints.wallet ,{
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': localStorage.getItem(`${window.location.hostname}_user`) ? "Bearer" + JSON.parse(localStorage.getItem(`${window.location.hostname}_user`)).token : null
  //     },
  //     method: "GET",
  //     // signal: abortCont.signal
  //     })
  //     .then(res => res.json())
  //     .then(data => {
  //         // console.log(data);
  //         dispatch(walletData(String(data)))
  //     })
  //     .catch(err => {
  //         dispatch(walletData(""))
  // })
  // }

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem(`${window.location.hostname}_user`)
    );
    if (
      userData &&
      userData?.data_completed !== "1" &&
      !["teacher", "admin"].includes(userData?.role ?? "")
    )
      navigate("/complete-info");
  }, [window.location.pathname]);

  useEffect(() => {
    fetch(endpoints.me, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.uid) {
          dispatch(userData(res));
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem(`${window.location.hostname}_user`);
        navigate("/");
      });
  }, []);

  // const checkIfTeacher = async () => {
  //   fetch(endpoints.isTeacher, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: getToken(),
  //     },
  //     method: "POST",
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log("is teacher :", data);
  //       dispatch(userIsTeacher(data));
  //       if (data) setIsTeacher(true);
  //       return data;
  //     })
  //     .catch((err) => {
  //       // dispatch(userIsTeacher(""));
  //       return err;
  //     });
  // };

  // const setTeacher = (teacher) => {
  //   dispatch(currentTeacherData(teacher.category));
  //   const logo = document.getElementById("logo-webp");
  //   logo.src = teacher.logoUrl;
  //   const favicon = document.getElementById("teacher-favicon");
  //   favicon.href = teacher.favicon;
  //   document.title = teacher.name;
  //   console.log("setTeacher", teacher);
  // };

  // useEffect(() => {
  //   // if (domain === "localhost") {
  //   //   setTeacher({
  //   //     category: "uncategorized",
  //   //     // category: "mr-hesham",
  //   //     // category: "dr-tamer-zakaria",
  //   //     // category: "mr-hazem-el-kashif",
  //   //     // category: "mr-mohamed-galal",
  //   //     // category: "monsieur-mohamed-yehia-abo-el-enein",
  //   //     logoUrl:
  //   //       "https://api.eliteacademyeg.com/wp-content/uploads/2024/01/firebase-logo.png",
  //   //     favicon:
  //   //       "https://api.eliteacademyeg.com/wp-content/uploads/2024/01/firebase-logo.png",
  //   //     name: "Mr Saif Salem",
  //   //   });
  //   // }
  //   fetch(endpoints.setPortal + `?portal=${window.location.hostname}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTeacher({
  //         category: data.category,
  //         logoUrl: data.logoUrl,
  //         favicon: data.favicon,
  //         name: data.name,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       localStorage.removeItem(`${window.location.hostname}_user`);
  //       dispatch(userData(null));
  //       navigate("/");
  //     });
  //   // if(user){
  //   //   fetchWallet()
  //   // }
  //   if (user) {
  //     checkIfTeacher();
  //   }
  //   dispatch(teachersData(teachers));
  //   if (user) {
  //     setMainPage("/categories");
  //   } else {
  //     setMainPage("/");
  //   }
  // }, [user, teachers]);

  useEffect(() => {
    if (user) {
      // checkIfTeacher();
      setMainPage("/school-grades");
    } else {
      setMainPage("/");
    }
  }, [user]);

  const { portalRefetch, userRefetch, portal } = useSelector(
    (state) => state.store
  );

  useEffect(() => {
    fetch(endpoints.getPortal + `?portal=${window.location.hostname}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(setPortal(result));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [portalRefetch]);

  useEffect(() => {
    if (user) {
      fetch(endpoints.getUser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(setUserProfileData(result));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRefetch, user]);

  const userProfileData = useSelector((state) => state.store.userProfileData);

  useEffect(() => {
    console.log("current portal after update", portal);

    if (portal) {
      // update teacher data
      dispatch(currentTeacherData(portal?.category));
      const logo = document.getElementById("logo-webp");
      logo.src = portal?.logoUrl;
      const favicon = document.getElementById("teacher-favicon");
      favicon.href = portal?.favicon;
      document.title = portal?.name;
    }
  }, [portal]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-light bg-gradient">
      <div className="container-fluid">
        <img id="logo-webp" className="mx-4" src="" alt="" height="50" />
        <button
          className="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav fw-bold">
            <li className="nav-item">
              {user && (
                <Link className="nav-link grow" to={mainPage}>
                  الرئيسية
                </Link>
              )}
            </li>
            <li className="nav-item">
              {user && ["teacher", "admin"].includes(user.role) && (
                <Link className="nav-link grow" to="/dashboard">
                  داشبورد المدرس
                </Link>
              )}
            </li>
            <li className="nav-item">
              {user && (
                <Link className="nav-link grow" to="/account">
                  حسابي
                </Link>
              )}
            </li>
            {/* <li className="nav-item">
          {user && <Link id="my-wallet" className="nav-link grow" aria-current="page" to="/wallet">
            محفظتي
          </Link>}
        </li> */}
            <li className="nav-item">
              {user && (
                <Link id="my-quizzes" className="nav-link grow" to="/grades">
                  امتحاناتي
                </Link>
              )}
            </li>
            <li className="nav-item font-weight-bold">
              {user && (
                <Link
                  id="my-purchases"
                  className="nav-link grow"
                  to="/invoices"
                >
                  مشترياتي
                </Link>
              )}
            </li>
            {/* {(domain === "localhost" || domain.includes("galal")) && (
              <li className="nav-item font-weight-bold">
                {user && (
                  <Link
                    id="my-purchases"
                    className="nav-link p-2 text-danger grow"
                    to="/ai-assistant"
                  >
                    AI Assistant
                  </Link>
                )}
              </li>
            )} */}
            <li id="log-me-out" className="nav-item me-3">
              {window.location.pathname !== "/" && (
                <Logout className="nav-link" />
              )}
            </li>
            {/* <li id="log-me-out" className="nav-item me-3">
              <button
                onClick={async () => {
                  const res = await (
                    await fetch(
                      "https://api.eliteacademyeg.com/wp-json/elite/v1/test-attendance-2"
                    )
                  ).json();
                  try {
                    console.log("cdvsafewew", unicodeToText(res.offline_group.split('-')));
                  } catch (e) {
                    console.log("cdvsafewew", e);
                  }
                }}
                className="btn ea-btn rounded-ea"
              >
                Test
              </button>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
