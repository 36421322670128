import { useEffect } from "react";
import Spinner from "../Spinner.js";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";
import NotFound from "../NotFound.js";
import Animation from "../Animation.jsx";
import { endpoints } from "../../hooks/endpoints.js";

const Invoice = () => {
  const { id } = useParams();

  const {
    data: invoice,
    isPending,
    error,
  } = useFetch(`${endpoints.invoice}?invoice=${id}`);

  console.log("invoice", invoice);

  useEffect(() => {
    /*EliteTransition()*/
  }, []);

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
          عملية شراء رقم ({id})
        </h2>
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {invoice && (
            <>
              <div className="alert alert-primary mt-3" role="alert">
                التاريخ : ( {invoice.date} ) - الحالة : ({" "}
                {invoice.status === "completed" ? (
                  <p className="d-inline text-success fw-bold">تم الدفع</p>
                ) : invoice.status === "pending" ? (
                  <p className="d-inline text-danger fw-bold">منتظر الدفع</p>
                ) : (
                  invoice.status
                )}{" "}
                )
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">الحصة</th>
                    <th scope="col">القيمة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{invoice.lesson}</th>
                    <td>{invoice.subtotal} جنيه</td>
                  </tr>
                  {/* <tr>
                    <th scope="row">المجموع</th>
                    <td>{invoice.subtotal} جنيه</td>
                  </tr> */}
                  <tr>
                    <th scope="row">شراء بالكود</th>
                    <td>
                      {invoice.code ? (
                        invoice.code
                      ) : (
                        <i class="bi bi-dash-lg"></i>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">الإجمالي</th>
                    <td>{invoice.discount ? 0 : invoice.total}</td>
                  </tr> */}
                </tbody>
              </table>
            </>
          )}
          {error && <NotFound />}
        </div>
        {invoice && invoice === "Not the customer" && <NotFound />}
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default Invoice;
