import Spinner from "../Spinner.js";
import { NavLink as Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../hooks/BaseUrl.js";
import Animation from "../Animation.jsx";
import { endpoints } from "../../hooks/endpoints.js";
import { getToken } from "../../hooks/utils.js";

const Invoices = () => {
  const [invoicesStart, setInvoicesStart] = useState(0);
  const [invoices, setInvoices] = useState();
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const { currentTeacher } = useSelector((state) => state.store);

  useEffect(() => {
    /*EliteTransition()*/
    if (!invoices && currentTeacher) {
      fetch(`${endpoints.invoices}?teacher=${currentTeacher}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == 404) {
            setInvoices(data);
            return;
          }
          setInvoices(
            data.filter((invoice) => invoice.cat_slug.includes(currentTeacher))
          );
          setIsPending(false);
        })
        .catch((error) => {
          setError(error);
          setIsPending(false);
        });
    }
  }, [invoicesStart, currentTeacher]);

  if (invoices?.status === 404) {
    return (
      <Animation>
        <div className="container responsive-pages py-3">
          <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
            مشترياتي
          </h2>
          <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow p-2">
            <h2 className="text-center">لا يوجد مشتريات</h2>
          </div>
        </div>
      </Animation>
    );
  }

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
          مشترياتي
        </h2>
        {/* {invoices && <h4 className="p-2">إجمالي المشتريات ({invoices.length})</h4>} */}
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {invoices && (
            <Animation>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">رقم العملية</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الإجمالي</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    invoices.map(
                      (invoice, index) =>
                        index >= invoicesStart &&
                        index < invoicesStart + 10 && (
                          <tr>
                            <th scope="row">{invoice.id}</th>
                            <td>{invoice.date}</td>
                            <td>
                              {invoice.status === "completed" ? (
                                <p className="text-success fw-bold">تم الدفع</p>
                              ) : invoice.status === "pending" ? (
                                <p className="text-danger fw-bold">
                                  منتظر الدفع
                                </p>
                              ) : (
                                invoice.status
                              )}
                            </td>
                            <td>{invoice.total} جنيه</td>
                            <td>
                              <Link
                                to={`/invoices/${invoice.id}`}
                                style={{
                                  maxHeight: "30px",
                                  padding: "3px 8px",
                                }}
                                className="pt-0 btn ea-btn rounded-ea"
                              >
                                تفاصيل
                              </Link>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Animation>
          )}
          <div className="d-flex">
            {invoices && invoicesStart + 10 < invoices.length && (
              <button
                onClick={() => setInvoicesStart((prev) => prev + 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {invoices && invoicesStart > 0 && (
              <button
                onClick={() => setInvoicesStart((prev) => prev - 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>
          {error && <h2>يجب تسجيل الدخول اولا</h2>}
        </div>
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default Invoices;
