import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import "../../styles/eliteVideoPlayer.css";
import { baseUrl } from "../../hooks/BaseUrl";
import { endpoints } from "../../hooks/endpoints";

function EliteVideoPlayer({ vidId, expirtDate, lessonData }) {
  const playerId = vidId;
  const playerRef = useRef();
  const selectQualityRef = useRef();
  const [playerReady, setPlayerReady] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [decremented, setDecremented] = useState(false);

  useEffect(() => {
    if (decremented) {
      const userInLocalStorage = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      );

      // Check if video progress is 80% or more
      if (
        //&& progress <= 85
        JSON.parse(lessonData.videos_data).length < 2 &&
        expirtDate !== "infinite"
      ) {
        setDecremented(true);
        // Send fetch request to the backend API
        fetch(endpoints.videoView, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInLocalStorage.token}`,
          },
          body: JSON.stringify({
            lesson_id: lessonData.lesson_id,
          }),
          redirect: "follow",
        })
          .then((response) => {
            // Handle the response
            console.log(response);
            console.log(
              "video view",
              `80% of video reached for lesson id ${lessonData.lesson_id}`
            );
            setDecremented(true);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      }
    }
  }, [decremented]);

  useEffect(() => {
    const timeUpdater = setInterval(() => {
      const currentVidTime = playerRef.current?.getCurrentTime();
      const totalVidTime = playerRef.current?.getDuration();
      if (currentVidTime) {
        setCurrentTime(currentVidTime);
        document.getElementById(`timeline-${playerId}`).value = currentVidTime;
        if ((currentVidTime / totalVidTime) * 100 > 80 && !decremented) {
          setDecremented(true);
        }
      }
    }, 100);

    return () => {
      clearInterval(timeUpdater);
    };
  }, []);

  document.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement !== null) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  });

  const getReference = (e) => {
    console.log("testPlayer", e.target);
    setPlayerReady(true);
    playerRef.current = e.target;
    setTotalTime(e.target.getDuration());
    document.addEventListener("keydown", (event) => {
      switch (event.key) {
        case " ":
          // togglePlayPause();
          event.preventDefault();
          break;
        case "m":
          event.preventDefault();
          // toggleMuteState();
          break;
        case "ArrowLeft":
          event.preventDefault();
          // skipTime(-10);
          break;
        case "ArrowRight":
          event.preventDefault();
          // skipTime(10);
          break;
      }
    });
    e.target.seekTo(0);
    e.target.pauseVideo();
  };

  const toggleFullScreen = () => {
    if (!fullScreen) {
      setFullScreen(true);
      document.getElementById(`video-${playerId}`).requestFullscreen();
    } else {
      setFullScreen(false);
      document.exitFullscreen();
    }
  };

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player.getPlayerState() === 1) {
      player.pauseVideo();
      setPlayVideo(false);
    } else {
      player.playVideo();
      setPlayVideo(true);
    }
  };

  const toggleMuteState = () => {
    if (muted) {
      setMuted(false);
      const player = playerRef.current;
      player.unMute();
    } else {
      setMuted(true);
      const player = playerRef.current;
      player.mute();
    }
  };

  const skipTime = (value) => {
    const player = playerRef.current;
    player.seekTo(player.getCurrentTime() + value);
  };

  const updateVolume = (value) => {
    const player = playerRef.current;
    if (player.isMuted()) {
      setMuted(false);
      player.unMute();
    }
    setVolume(value);
    player.setVolume(value);
  };

  const updatePlaybackRate = (e) => {
    playerRef.current.setPlaybackRate(+e.target.value);
  };

  const updatePlaybackQuality = (e) => {
    playerRef.current.setPlaybackQuality(e.target.value);
  };

  const updateTimeline = (e) => {
    setCurrentTime(+e.target.value);
    playerRef.current.seekTo(+e.target.value);
  };

  return (
    <div
      onClick={togglePlayPause}
      id={`video-${playerId}`}
      className="ea-video-container w-100"
      dir="ltr"
      style={{ display: playerReady ? "flex" : "none" }}
    >
      <div
        id={`ea-video-controls-container-${playerId}`}
        onClick={(e) => e.stopPropagation()}
        className="ea-video-controls-container"
      >
        <div className="ea-timeline-container py-0 px-3">
          <input
            id={`timeline-${playerId}`}
            type="range"
            max={totalTime}
            min={0}
            step={0.25}
            // value={currentTime}
            className="ea-timeline w-100 form-range"
            onChange={updateTimeline}
          />
        </div>
        <div className="ea-controls pt-0">
          <button onClick={togglePlayPause} className="ea-play-pause-btn">
            {!playVideo ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-play-circle ea-play-icon"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-pause-circle ea-pause-icon"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
              </svg>
            )}
          </button>
          <button onClick={() => skipTime(-10)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-skip-backward-fill"
              viewBox="0 0 16 16"
            >
              <path d="M.5 3.5A.5.5 0 0 0 0 4v8a.5.5 0 0 0 1 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 0 0-.5-.5z" />
            </svg>
          </button>
          <button onClick={() => skipTime(10)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-skip-forward-fill"
              viewBox="0 0 16 16"
            >
              <path d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5z" />
            </svg>
          </button>
          <div className="ea-volume-container">
            <button onClick={toggleMuteState} className="ea-volume-btn">
              {volume > 40 && !muted && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-volume-up-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                  <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
                  <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
                </svg>
              )}
              {volume > 0 && volume <= 40 && !muted && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-volume-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zm3.025 4a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z" />
                </svg>
              )}
              {(volume == 0 || muted) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-volume-mute-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
                </svg>
              )}
            </button>
            <input
              onChange={(e) => updateVolume(e.target.value)}
              type="range"
              min={0}
              max={100}
              step={1}
              value={muted ? 0 : volume}
              className="ea-volume-slider form-range"
            />
          </div>
          <div className="ea-duration-container">
            <div className="ea-current-time">{`${
              totalTime > 3600 ? `${Math.floor(currentTime / 3600)}:` : ""
            }${String(Math.floor(currentTime / 60) % 60).padStart(
              2,
              "0"
            )}:${String(Math.floor(currentTime % 60)).padStart(2, "0")}`}</div>
            /
            <div className="ea-total-time">{`${
              totalTime > 3600 ? `${Math.floor(totalTime / 3600)}:` : ""
            }${String(Math.floor(totalTime / 60) % 60).padStart(
              2,
              "0"
            )}:${String(Math.floor(totalTime % 60)).padStart(2, "0")}`}</div>
          </div>
          <div className="ea-playback">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-fast-forward-fill mx-1"
              viewBox="0 0 16 16"
            >
              <path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
              <path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
            </svg>
            <select onChange={updatePlaybackRate} className="ea-playback-speed">
              <option value={0.5}>0.5x</option>
              <option selected value={1}>
                1x
              </option>
              <option value={1.25}>1.25x</option>
              <option value={1.5}>1.5x</option>
              <option value={1.75}>1.75x</option>
              <option value={2}>2x</option>
            </select>
          </div>
          <div
            onClick={() => selectQualityRef.current.click()}
            className="ea-playback"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-gear-fill mx-1"
              viewBox="0 0 16 16"
            >
              <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
            </svg>
            <select
              ref={selectQualityRef}
              onChange={updatePlaybackQuality}
              className="ea-playback-quality"
            >
              <option value="small">360p</option>
              <option selected value="medium">
                480p
              </option>
              <option value="large">720p60</option>
              <option value="hd1080">1080p60</option>
            </select>
          </div>
          <button onClick={toggleFullScreen} className="ea-full-screen-btn">
            {!fullScreen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-fullscreen"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-fullscreen-exit"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z" />
              </svg>
            )}
          </button>
        </div>
      </div>
      <YouTube
        className="w-100"
        videoId={vidId}
        onReady={getReference}
        onContextMenu={(event) => event.preventDefault()}
        opts={{
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 0,
            enablejsapi: 1,
            rel: 0,
            autoplay: 0,
            disablekb: 1,
            iv_load_policy: 3,
            loop: 1,
            start: 0,
          },
        }}
      />
    </div>
  );
}

export default EliteVideoPlayer;
