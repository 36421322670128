import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userProfileData: null,
  isTeacher: false,
  currentTeacher: null,
  teachers: null,
  wallet: "",
  purchases: [],
  portal: null,
  portalRefetch: 0,
  userRefetch: 0,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    userData: (state, action) => {
      state.user = action.payload;
    },
    setUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    updateUserPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    currentTeacherData: (state, action) => {
      state.currentTeacher = action.payload;
    },
    userIsTeacher: (state, action) => {
      state.isTeacher = action.payload;
    },
    teachersData: (state, action) => {
      state.teachers = action.payload;
    },
    walletData: (state, action) => {
      state.wallet = action.payload;
    },
    setPortal: (state, action) => {
      state.portal = action.payload;
    },
    invokePortalRefetch: (state) => {
      state.portalRefetch += 1;
    },
    invokeUserRefetch: (state) => {
      state.userRefetch += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userData,
  teachersData,
  walletData,
  userIsTeacher,
  currentTeacherData,
  updateUserPurchases,
  setPortalData,
  setCats,
  setCatLessons,
  setPortal,
  setUserProfileData,
  invokePortalRefetch,
  invokeUserRefetch,
} = storeSlice.actions;

export default storeSlice.reducer;
