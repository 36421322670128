import React, { useEffect, useRef, useState } from "react";
import Animation from "../Animation";
import { Html5Qrcode } from "html5-qrcode";
import { getToken, textToUnicode, unicodeToText } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";
import { useSelector } from "react-redux";

const AttendanceDash = () => {
  const [studentData, setStudentData] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [canSubmitAttendance, setCanSubmitAttendance] = useState(false);
  const [submitedMsg, setSubmitedMsg] = useState(false);
  const [attendedStudents, setAttendedStudents] = useState(
    JSON.parse(localStorage.getItem("student_attendance")) ?? []
  );
  const [currentOfflineGroup, setCurrentOfflineGroup] = useState("");
  const [currentWeek, setCurrentWeek] = useState("");
  const [attachToLesson, setAttachToLesson] = useState(false);
  const [currentGrade, setCurrentGrade] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [startedTakingAttendance, setStartedTakingAttendance] = useState(false);

  const portal = useSelector((state) => state.store?.portal);
  const offlineGroups = portal?.offline_groups;
  const grades = portal?.cats?.filter((item) => item.parent === 0);
  const groups = portal?.cats?.filter((item) => item.parent !== 0);
  const lessons = portal?.data?.filter(
    (item) => String(item.term_taxonomy_id) === String(currentGroup ?? 0)
  );

  const cameraId = useRef(null);
  const html5QrCode = useRef(null);

  const startScan = () => {
    setStartedTakingAttendance(true);
    Html5Qrcode.getCameras()
      .then((devices) => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
        if (devices && devices.length) {
          cameraId.current = devices[0].id;
          // .. use this to start scanning.
          html5QrCode.current = new Html5Qrcode(/* element id */ "reader");
          setScanning(true);
          html5QrCode.current
            .start(
              cameraId.current,
              {
                fps: 10, // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
              },
              (decodedText, decodedResult) => {
                // do something when code is read
                const res = JSON.parse(decodedText);
                setStudentData({
                  id: res.uid,
                  name: unicodeToText(res.name),
                  group: unicodeToText(res.group),
                  grade: 0,
                  portal: res.portal,
                });
                console.log({ res });
              },
              (errorMessage) => {
                // parse error, ignore it.
                // console.log({ errorMessage });
              }
            )
            .catch((err) => {
              // console.log({ err });
              // Start failed, handle it.
            });
        }
      })
      .catch((err) => {
        // handle err
      });
  };

  const stopScan = () => {
    html5QrCode.current
      ?.stop()
      .then((ignore) => {
        // QR Code scanning is stopped.
        html5QrCode.current.clear();
        setScanning(false);
        setStudentData(null);
        setCanSubmitAttendance(true);
      })
      .catch((err) => {
        // Stop failed, handle it.
      });
  };

  const acceptStudent = () => {
    setAttendedStudents((prev) => [
      ...prev.filter((student) => student.id !== studentData.id),
      { ...studentData },
    ]);
    setStudentData(null);
  };

  const rejectStudent = () => {
    setStudentData(null);
  };

  const removeStudent = (id) => {
    setAttendedStudents((prev) => prev.filter((student) => student.id !== id));
  };

  const addStudentGrade = ({ id, grade }) => {
    if (!/^[0-9]+$/.test(+grade) && grade !== "") return;
    setAttendedStudents((prev) => {
      const student = prev.find((student) => student.id === id);
      student.grade = grade;
      return [...prev.filter((student) => student.id !== id), student];
    });
  };

  const getStudentsForSubmition = () => {
    const students = JSON.parse(localStorage.getItem("student_attendance"));
    return students?.map((student) => student.id);
  };

  const getStudentsGradesForSubmition = () => {
    const students = JSON.parse(localStorage.getItem("student_attendance"));
    return students?.map((student) => student.id + "-" + student.grade);
  };

  const submitAttendance = () => {
    fetch(endpoints.submitAttendance, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({
        students: getStudentsForSubmition(),
        grades: getStudentsGradesForSubmition(),
        offline_group_unicode: textToUnicode(currentOfflineGroup).join("-"),
        offline_group: currentOfflineGroup,
        attach_to_lesson: attachToLesson,
        week: currentWeek,
        lesson_id: currentLesson,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("attendance", data);
        localStorage.removeItem("student_attendance");
        setAttendedStudents([]);
        setCanSubmitAttendance(false);
        setSubmitedMsg(true);
        setAttachToLesson(false);
        setStartedTakingAttendance(false);
        setCurrentGrade(null);
        setCurrentGroup(null);
        setCurrentLesson(null);
        setCurrentOfflineGroup("");
        setCurrentWeek("");
      })
      .catch((err) => console.log("attendance", err));
  };

  const isAttendanceDisabled = () => {
    if (!currentOfflineGroup || !currentWeek) return true;
    if (attachToLesson && (!currentGrade || !currentGroup || !currentLesson))
      return true;
    return false;
  };

  const availableWeeks = () => {
    const weeks = [];
    for (let i = 1; i <= 100; i++) {
      weeks.push(<option value={`w${i}`} key={i}>{`الأسبوع ${i}`}</option>);
    }
    return weeks;
  };

  useEffect(() => {
    localStorage.setItem(
      "student_attendance",
      JSON.stringify(attendedStudents)
    );
  }, [attendedStudents]);

  useEffect(() => {
    if (submitedMsg) {
      setTimeout(() => {
        setSubmitedMsg(false);
      }, 10000);
    }
  }, [submitedMsg]);

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
          الحضور و الغياب
        </h3>
        <div className="m-3">
          {!startedTakingAttendance && (
            <>
              <select
                value={currentWeek}
                onChange={(e) => setCurrentWeek(e.target.value)}
                className="form-select mb-3 rounded-ea"
                name="week"
                aria-label="Default select example"
              >
                <option value="">إختر الأسبوع</option>
                {availableWeeks()}
              </select>
              <select
                value={currentOfflineGroup}
                onChange={(e) => setCurrentOfflineGroup(e.target.value)}
                className="form-select mb-3 rounded-ea"
                name="offline_group"
                aria-label="Default select example"
              >
                <option value="">إختر المجموعة</option>
                {offlineGroups?.map((offlineGroup) => (
                  <option value={offlineGroup}>{offlineGroup}</option>
                ))}
              </select>
              <div className="form-check form-switch my-3">
                <input
                  onChange={() => setAttachToLesson((prev) => !prev)}
                  className="form-check-input"
                  type="checkbox"
                  id="attach-to-lesson"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="attach-to-lesson"
                >
                  هل تريد إرفاق تقرير الحضور مع حصة أونلاين
                </label>
              </div>
              {attachToLesson && (
                <>
                  <select
                    value={currentGrade}
                    onChange={(e) => setCurrentGrade(e.target.value)}
                    className="form-select mb-3 rounded-ea"
                    name="grade"
                    aria-label="Default select example"
                  >
                    <option value="">إختر المرحلة</option>
                    {grades?.map((grade) => (
                      <option value={grade.term_taxonomy_id}>
                        {grade.name.indexOf("|") > -1
                          ? grade.name.split("|")[0]
                          : grade.name}
                      </option>
                    ))}
                  </select>
                  {currentGrade && (
                    <>
                      <select
                        value={currentGroup}
                        onChange={(e) => setCurrentGroup(e.target.value)}
                        className="form-select mb-3 rounded-ea"
                        name="group"
                        aria-label="Default select example"
                      >
                        <option value="">إختر المجموعة</option>
                        {groups?.map((group) => (
                          <option value={group.term_taxonomy_id}>
                            {group.name.indexOf("|") > -1
                              ? group.name.split("|")[0]
                              : group.name}
                          </option>
                        ))}
                      </select>
                      {currentGroup && (
                        <select
                          value={currentLesson}
                          onChange={(e) => setCurrentLesson(e.target.value)}
                          className="form-select mb-3 rounded-ea"
                          name="group"
                          aria-label="Default select example"
                        >
                          <option value="">إختر الحصة</option>
                          <option value="later_lesson">حصة لاحقة</option>
                          {lessons?.map((lesson) => (
                            <option value={lesson.ID}>
                              {lesson.post_title}
                            </option>
                          ))}
                        </select>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {attendedStudents.length > 0 && (
            <div className="table-responsive">
              <table className="table table-striped table-hover m-2">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">الطالب</th>
                    <th scope="col">الدرجة</th>
                    <th scope="col">المجموعة</th>
                  </tr>
                </thead>
                <tbody>
                  {attendedStudents?.map((student, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td className="d-flex align-items-center justify-content-between">
                          <button
                            className="mx-1 btn"
                            onClick={() => removeStudent(student.id)}
                          >
                            <i className="bi bi-x-circle-fill text-danger"></i>
                          </button>
                          {student.name}
                        </td>
                        <td>
                          <input
                            type="text"
                            maxLength={3}
                            className="form-control attendance-grade-width"
                            value={student.grade}
                            onChange={(e) =>
                              addStudentGrade({
                                id: student.id,
                                grade: e.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          {student.group === currentOfflineGroup
                            ? student.group
                            : "طالب جديد"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div id="reader" className="w-75 mx-auto my-2"></div>
          <div className="d-flex">
            {!scanning ? (
              <button
                className="btn ea-btn rounded-ea w-48 mx-auto"
                onClick={startScan}
                disabled={isAttendanceDisabled()}
              >
                تسجيل حضور
              </button>
            ) : (
              <button
                className="btn ea-btn rounded-ea w-48 mx-auto"
                onClick={stopScan}
              >
                إنهاء التسجيل
              </button>
            )}
            {canSubmitAttendance && attendedStudents.length > 0 && (
              <button
                className="btn ea-btn rounded-ea w-48 mx-auto"
                onClick={submitAttendance}
              >
                رفع تقرير الحضور
              </button>
            )}
          </div>
          {studentData && (
            <div className="my-2">
              {studentData.group === currentGroup?.current ? (
                <div className="alert alert-success rounded-ea" role="alert">
                  <p>الطالب: {studentData.name}</p>
                  <p>المجموعة: {studentData.group}</p>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-success rounded-ea mx-auto"
                      style={{ width: "48%" }}
                      onClick={acceptStudent}
                    >
                      قبول
                    </button>
                    <button
                      className="btn btn-danger rounded-ea mx-auto"
                      style={{ width: "48%" }}
                      onClick={rejectStudent}
                    >
                      رفض
                    </button>
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger rounded-ea" role="alert">
                  {studentData.portal === window.location.hostname ? (
                    <p>هذا الطالب ليس في هذه المجموعة</p>
                  ) : (
                    <p>طالب جديد</p>
                  )}
                  <p>الطالب: {studentData.name}</p>
                  {studentData.portal === window.location.hostname && (
                    <p>المجموعة: {studentData.group}</p>
                  )}
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-success rounded-ea mx-auto"
                      style={{ width: "48%" }}
                      onClick={acceptStudent}
                    >
                      قبول
                    </button>
                    <button
                      className="btn btn-danger rounded-ea mx-auto"
                      style={{ width: "48%" }}
                      onClick={rejectStudent}
                    >
                      رفض
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {submitedMsg && (
            <div
              className="alert alert-success rounded-ea text-center mt-2"
              role="alert"
            >
              تم رفع تقرير الحضور
            </div>
          )}
        </div>
      </div>
    </Animation>
  );
};

export default AttendanceDash;
